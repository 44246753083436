import { ReactNode } from 'react';
import {
    Box,
    Flex,
    Stack,
    VStack,
    Heading,
    Text,
    SimpleGrid,
} from '@chakra-ui/react';


import { DoctorProfile } from '../components/doctor/doctor-profile';
import { Footer } from '../components/footer';
import { TestimonialWithBubble } from '../components/testimonial';
import ThreeTierPricing from '../components/three-tier-pricing';
import { Hero } from '../components/hero';
import { Navbar } from '../components/navbar';
import { Highlight } from '../components/highlight';
import { Feature } from '../components/feature';
import { useEffect } from 'react';

export const Home = (props) => {
    const doctorArr = props.doctors.slice(0, 4);
    return (
        <Box px={{ lg: '32', sm: "2" }}>
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box p={4} w="full">
                <VStack>
                    {/* Hero start */}
                    <Hero />
                    {/* Hero end */}

                    {/* Features */}
                    <Highlight />
                    {/* End features */}

                    {/* Story Feature */}
                    <Feature />
                    {/* End of story feature */}

                    <Box w="full" borderBottomColor="gray.100" borderBottomWidth={1}></Box>
                    {/* Top specialists */}

                    <Box id="Doctors" px="auto" pt={12}>
                        <Heading mb={4}>Top Doctors</Heading>
                        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={24}>
                            { doctorArr.map((doctor)=>
                                <DoctorProfile btnBookVisible="visible" specialities={props.specialities} key={doctor.providerID} doctor={doctor}/>
                            )}
                        </SimpleGrid>
                    </Box>

                    {/* End features */}

                    <Box w="full" py={8} borderBottomColor="gray.100" borderBottomWidth={1}></Box>

                    {/* Three Tier Pricing start */}
                    <ThreeTierPricing id="Pricing" />
                    {/* Three Tier Pricing end */}

                    {/* Testimonial start */}
                    <TestimonialWithBubble />
                    {/* Testimonial end */}



                    {/* Newsletter start*/}
                    {/* <Newsletter /> */}
                    {/* Newsletter end */}


                    {/* Footer */}
                    <Footer />
                    {/* Footer end */}
                </VStack>
            </Box>
            {/* Main Content End */}
        </Box>
    );
}