import { Box, Button, Flex, FormControl, FormLabel, Img, Input, List, ListIcon, ListItem, Text, Textarea } from "@chakra-ui/react"
import axios from "axios"


import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { MdCheckCircle } from "react-icons/md"
export const NewArticle = () => {
    const [img, setImg] = useState("https://cdn.pixabay.com/photo/2020/03/19/21/25/laptop-4948837_960_720.jpg");
    const [selectedFile, setSelectedFile] = useState(null);
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };
    const inputFile = useRef(null)
    const [categories, setCategories] = useState([]);
    const [values, setValues] = useState({
        title: "",
        content: "",
        category: ""
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const AddCategory = () => {
        if(values.category!=""){
            categories.push(values.category)
            document.getElementById("category").value=""
            values.category = ""
            setCategories(categories)
            setValues(values)
        }
    }
    function addPhoto(articleID) {
        if (selectedFile == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("image", selectedFile);
        // var params = { "name": values.city, "country": values.country, "userID": userID, "type": userType }
        axios
            .post(Global.url + "/user/" + "article-" + articleID + "/photo", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Photo Update", res.data.message, "success").then(() => {
                        navigate("/article")
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }
    const AddArticle = () => {
        console.log(values)
        //authenticate here
        var token = localStorage.getItem("token")
        var authorID = localStorage.getItem("userID")
        if ((values.title == "") || (values.content == "") || (selectedFile == null)) {
            Swal.fire("Article",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/article", { "authorID": authorID+"", "title": values.title, "content": values.content, "categories": categories }, { headers: { "token": token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Article",
                        "Created successfully.",
                        "success").then(() => {
                            addPhoto(res.data.message+"")
                            // set token
                            
                        })
                } else {
                    Swal.fire("Error", "Error creating article", "error");
                }
            });
    }

    const openFile = () => {
        inputFile.current.click();
    }

    return (
        <Box
            flex={1}
            h="auto"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}
        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >New Article</Text>

            </Box>

            <Box p={8} w="100%">
                <Flex flexDir="column" >


                    <FormControl mt={4}>
                        <FormLabel>Title</FormLabel>
                        <Input name="title" onChange={onChange} type="text" placeholder="Title goes here" />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Content</FormLabel>
                        <Textarea rows={10} name="content" onChange={onChange} placeholder="Article content goes here" />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Categories</FormLabel>
                        <List spacing={3} mb={4}>
                            { categories.length != 0 ?
                                categories.map((category) =>
                                    <ListItem>
                                        <ListIcon key={category} as={MdCheckCircle} color='green.500' />
                                        {category}
                                    </ListItem>
                                ):<Text color={"teal"}>No categories</Text>
                            }
                        </List>
                        <FormLabel>Add Category</FormLabel>
                        <Flex flexDir="row">
                            <Input name="category" id="category" flex={5} mr={4} onChange={onChange} type="text" placeholder="Category goes here" />
                            <Button w="200px" flex={1} variant="outline" colorScheme="teal.500" onClick={AddCategory}>
                                Add Category
                            </Button>
                        </Flex>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Click to set photo</FormLabel>
                        <Img w="200px" h="200px" onClick={openFile} name="image" src={img} />
                        <Input type="file" ref={inputFile} onChange={onImageChange} visibility="hidden"></Input>
                    </FormControl>
                    <Box mt={4} w="full" alignItems="center">
                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddArticle}>
                            Create Article
                        </Button>
                    </Box>
                </Flex>
            </Box>
        </Box>
    )
}