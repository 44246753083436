import { Flex, HStack, IconButton, Input, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react"
import { ChatBubble } from "./ChatBubble";
import { IoMdSend,IoMdDocument } from 'react-icons/io'
import {HiChat} from 'react-icons/hi'

const messages = [
    // {
    //     message: "Hey Travis! Would you like to go out for coffee?",
    //     from: "others",
    //     dateSent: "20:21"
    // },
    // {
    //     message: "Sure! At 11:00am?",
    //     from: "me",
    //     dateSent: "20:22"
    // },
    // {
    //     message: "That's too early what about afternoon?",
    //     from: "others",
    //     dateSent: "20:23"
    // }
    // ,
    // {
    //     message: "That sounds good. Where should we meet?",
    //     from: "me",
    //     dateSent: "20:23"
    // }
]
const Chat = (props) => {
    return (
        <Flex w="full" flexDirection="column">
            <HStack px={4} py={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <IconButton
                    onClick={props.onChatHistoryOpen}
                    display={{base:"inherit",lg:"none"}}
                    icon={<HiChat/>}
                    arial-label="Toggle Chat History Drawer"
                />
                <Input variant="filled" rounded="full" placeholder="Search doctor" />
                <IconButton
                    onClick={props.onChatFilesOpen}
                    display={{base:"inherit",lg:"none"}}
                    icon={<IoMdDocument/>}
                    arial-label="Toggle Chat files Drawer"
                />
            </HStack>
            <Flex px={6} overflowY="auto" flexDirection="column" flex={1}>
                <Stat mt={6}>
                    <StatLabel color="gray.500">Chatting with</StatLabel>
                    <StatNumber>Dina Harrison</StatNumber>
                </Stat>
                {messages.length == 0 ? <Text align="center" mb={16}>No messages</Text>:""}
                {messages.map(({ message, from, dateSent }, index) => (
                    <ChatBubble
                        key={index}
                        message={message}
                        from={from}
                        dateSent={dateSent} />
                ))}
            </Flex>
            <Flex pl={4} pr={2} borderTopColor="gray.100" borderTopWidth={1}>
                <Input variant="unstyled" placeholder="Type your message here" />
                <IconButton
                    colorScheme="blue"
                    aria-label="Send message"
                    variant="ghost"
                    icon={<IoMdSend />}
                />
            </Flex>
        </Flex>
    )

}
export default Chat;