import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    IconButton,
    Button,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import {
    FaPhone,
    FaEnvelopeOpen,
    FaLocationArrow,
    FaFacebook,
    FaInstagram,
    FaTwitter,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Footer } from '../components/footer';

import { Navbar } from '../components/navbar';
import { Global } from '../utils/global';





export const ContactUs = () => {
    const [values, setValues] = useState({
        name: "",
        email: "",
        message: "",
        phoneNumber: ""
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const addContact = () => {
        console.log(values)
        //authenticate here
        axios
            .post(Global.url + "/contact", { name: values.name, email: values.email, message: values.message, phoneNumber: values.phoneNumber })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Contact Us",
                        "Added successfully.",
                        "success").then(() => {
                            navigate("/")
                        })
                } else {
                    Swal.fire("Contact us error", res.data.message, "error");
                }
            });
    }
    return (
        <Box px={{ lg: '32', sm: "2" }}>
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box p={4} w="full">
                <Container bg="gray.100" maxW="full" mt={0} centerContent overflow="hidden">
                    <Flex>
                        <Box
                            bg="white"
                            color="black"
                            borderRadius="lg"
                            m={{ sm: 4, md: 16, lg: 10 }}
                            p={{ sm: 5, md: 5, lg: 16 }}>
                            <Box p={4}>
                                <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                                    <WrapItem>
                                        <Box>
                                            <Heading>Contact</Heading>
                                            <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                                                Fill up the form beside to contact
                                            </Text>
                                            <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                                                <VStack pl={0} spacing={3} alignItems="flex-start">
                                                    <Button
                                                        size="md"
                                                        height="48px"
                                                        width="200px"
                                                        variant="ghost"
                                                        color="black"
                                                        _hover={{ border: '2px solid #008080' }}
                                                        leftIcon={<FaPhone color="#008080" size="20px" />}>
                                                        +254 711 252 9248
                                                    </Button>
                                                    <Button
                                                        size="md"
                                                        height="48px"
                                                        width="200px"
                                                        variant="ghost"
                                                        color="black"
                                                        _hover={{ border: '2px solid #008080' }}
                                                        leftIcon={<FaEnvelopeOpen color="#008080" size="20px" />}>
                                                        info@quickmed.com
                                                    </Button>
                                                    <Button
                                                        size="md"
                                                        height="48px"
                                                        width="200px"
                                                        variant="ghost"
                                                        color="black"
                                                        _hover={{ border: '2px solid #008080' }}
                                                        leftIcon={<FaLocationArrow color="#008080" size="20px" />}>
                                                        Nairobi, Kenya
                                                    </Button>
                                                </VStack>
                                            </Box>
                                            <HStack
                                                mt={{ lg: 10, md: 10 }}
                                                spacing={5}
                                                px={5}
                                                alignItems="flex-start">
                                                <IconButton
                                                    aria-label="facebook"
                                                    variant="ghost"
                                                    size="lg"
                                                    isRound={true}
                                                    _hover={{ bg: '#008080' }}
                                                    icon={<FaFacebook size="28px" />}
                                                />
                                                <IconButton
                                                    aria-label="twitter"
                                                    variant="ghost"
                                                    size="lg"
                                                    isRound={true}
                                                    _hover={{ bg: '#008080' }}
                                                    icon={<FaTwitter size="28px" />}
                                                />
                                                <IconButton
                                                    aria-label="instagram"
                                                    variant="ghost"
                                                    size="lg"
                                                    isRound={true}
                                                    _hover={{ bg: '#008080' }}
                                                    icon={<FaInstagram size="28px" />}
                                                />
                                            </HStack>
                                        </Box>
                                    </WrapItem>
                                    <WrapItem>
                                        <Box bg="white" borderRadius="lg">
                                            <Box m={8} color="#0B0E3F">
                                                <VStack spacing={5}>
                                                    <FormControl id="name">
                                                        <FormLabel>Your Name</FormLabel>
                                                        <InputGroup borderColor="#E0E1E7">
                                                            <Input name="name" type="text" size="md" onChange={onChange} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl id="email">
                                                        <FormLabel>Email</FormLabel>
                                                        <InputGroup borderColor="#E0E1E7">
                                                            <Input name="email" type="text" size="md" onChange={onChange} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl id="phoneNumber">
                                                        <FormLabel>Phone Number</FormLabel>
                                                        <InputGroup borderColor="#E0E1E7">
                                                            <Input name="phoneNumber" type="text" size="md" onChange={onChange} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl id="message">
                                                        <FormLabel>Message</FormLabel>
                                                        <Textarea
                                                            name="message"
                                                            borderColor="gray.300"
                                                            _hover={{
                                                                borderRadius: 'gray.300',
                                                            }}
                                                            placeholder="message"
                                                            onChange={onChange}
                                                        />
                                                    </FormControl>
                                                    <FormControl id="name" float="right">
                                                        <Button
                                                            variant="outline"
                                                            colorScheme="teal"
                                                            onClick={addContact}
                                                        >
                                                            Send Message
                                                        </Button>
                                                    </FormControl>
                                                </VStack>
                                            </Box>
                                        </Box>
                                    </WrapItem>
                                </Wrap>
                            </Box>
                        </Box>
                    </Flex>
                </Container>
            </Box>
            {/* Main Content End */}
            <Footer />
        </Box>
    );
}