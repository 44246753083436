import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Box,
    Icon,
    Text,
    HStack,
    Flex,
    Heading,
    VStack,
    Button,
} from '@chakra-ui/react'
import axios from 'axios';
import { useEffect, useState } from 'react';

import { FiArrowUpRight, FiCheck, FiMail } from 'react-icons/fi'
import Swal from 'sweetalert2';
import { Global } from '../../utils/global';



export const Assessment = () => {
    const [history, setHistory] = useState([])

    const getMedicalHistory = async (userID) => {
        var token = localStorage.getItem("token");
        axios
            .get(Global.url + "/patient/" + userID + "/checkup", { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setHistory(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });

    };
    useEffect(() => {
        var userID = localStorage.getItem("userID")
        var userType = localStorage.getItem("userType")
        if (userType == "patient") {
            getMedicalHistory(userID)
        }
    })

    return (
        <Box
            flex={1}
            h="95vh"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Assessment</Text>
                <Text textAlign="start" fontSize={20} fontWeight="normal" color="gray.500" >Assessment records</Text>
            </Box>

            <Box p={8} w="full">
                <TableContainer>
                    <Table variant='simple'>
                        <TableCaption>Medical history</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Diagnosis</Th>
                                <Th>Notes</Th>
                                <Th>Medicine</Th>
                                <Th>Allergies</Th>
                                <Th>Doctor</Th>
                                <Th>Prescription</Th>
                                <Th>Date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {history.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No medical history</Text></Tr> : ""}
                            {history.map((item) => (
                                <Tr>
                                    <Td>{item.diagnosis}</Td>
                                    <Td>{item.notes}</Td>
                                    <Td>{item.medicine}</Td>
                                    <Td>{item.allergies}</Td>
                                    <Td>{item.providerID}</Td>
                                    <Td>Download</Td>
                                    <Td>{item.dateAdded}</Td>
                                </Tr>
                            ))
                            }
                        </Tbody>
                        <Tfoot>
                            <Th>Diagnosis</Th>
                            <Th>Notes</Th>
                            <Th>Medicine</Th>
                            <Th>Allergies</Th>
                            <Th>Doctor</Th>
                            <Th>Prescription</Th>
                            <Th>Date</Th>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}