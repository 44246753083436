import { Flex } from "@chakra-ui/react"
import { NewArticle } from "../components/articles/new-article"
import { Sidebar } from "../components/sidebar/sidebar"

export const NewArticlePage = () => {
    return (
        <Flex>
            <Sidebar />
            <NewArticle/>
        </Flex>
    )
}