import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Login } from './pages/login';
import { SignUp } from './pages/signup';
import { ForgotPassword } from './pages/forgot-password';
import { ResetPassword } from './pages/reset-password';
import { Home } from './pages/home';
import { Doctor } from './pages/doctor';
import { Blog } from './pages/blog';
import { ContactUs } from './pages/contact-us';
import { RegisterDoctor } from './pages/register-doctor';
import axios from 'axios';
import { Global } from './utils/global';
import Swal from 'sweetalert2';
import { DashboardPage } from './pages/dashboard-page';
import { AppointmentsPage } from './pages/appointments-page';
import { NewAppointmentPage } from './pages/new-appointment-page';
import { BillingPage } from './pages/billing-page';
import { ChatPage } from './pages/chat-page';
import { HealthPlanPage } from './pages/health-plan-page';
import { AssessmentPage } from './pages/assessment-page';
import { NewPlanPage } from './pages/new-plan-page';
import { BookDoctor } from './pages/book-doctor';
import { ArticlesPage } from './pages/articles-page';
import { NewArticlePage } from './pages/new-article-page';
import { AccountConfirm } from './pages/account-confirm';
import { Subscribe } from './pages/subscribe';
import { Terms } from './pages/terms';
import { Policy } from './pages/policy';
import mixpanel from 'mixpanel-browser';
import { AppointmentSessionPage } from './pages/appointment-session-page';
import { ViewBlog } from './pages/view-blog';
mixpanel.init(Global.mixpanelToken,{
  debug:true
})

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      specialities: [],
      doctors: [],
      locations: []
    }
  }

  getSpecialities() {
    axios
      .get(Global.url + "/speciality")
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        console.log(res);
        if (res.data.success == 1) {
          this.setState({ specialities: res.data.message })
        } else {
          Swal.fire("Error", res.data.message, "error");
        }
      });
  };

  getLocations() {
    axios
      .get(Global.url + "/location")
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        console.log(res);
        if (res.data.success == 1) {
          this.setState({ locations: res.data.message })
        } else {
          Swal.fire("Error", res.data.message, "error");
        }
      });
  };

  getProviders() {
    axios
      .get(Global.url + "/provider/status/confirmed")
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        console.log(res);
        if (res.data.success == 1) {
          this.setState({ doctors: res.data.message })
        } else {
          Swal.fire("Error", res.data.message, "error");
        }
      });
  };
  componentDidMount() {
    this.getSpecialities()
    this.getProviders()
    this.getLocations()
  }
  render() {
    return (
      <ChakraProvider theme={theme}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home doctors={this.state.doctors} specialities={this.state.specialities}/>} />
            <Route path="/doctor" element={<Doctor locations={this.state.locations} specialities={this.state.specialities}  doctors={this.state.doctors}/>} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/view-blog" element={<ViewBlog />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/register-doctor" element={<RegisterDoctor specialities={this.state.specialities} />} />
            <Route path="/book-doctor" element={<BookDoctor />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/reset-password/:refID" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {/* Start of protected routes */}
            <Route path="/appointment" element={<AppointmentsPage />} />
            <Route path="/article" element={<ArticlesPage />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/new-appointment" element={<NewAppointmentPage />} />
            <Route path="/session" element={<AppointmentSessionPage />} />
            <Route path="/billing" element={<BillingPage />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/new-plan" element={<NewPlanPage />} />
            <Route path="/account/confirm/:refID" element={<AccountConfirm />} />
            <Route path="/account/confirm" element={<AccountConfirm />} />
            <Route path="/new-article" element={<NewArticlePage />} />
            <Route path="/plan" element={<HealthPlanPage />} />
            <Route path="/assessment" element={<AssessmentPage />} />
          </Routes>
        </Router>
        {/* <Dashboard/> */}

      </ChakraProvider>
    );
  }
}

export default App;
