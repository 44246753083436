import { Box, Text, VStack } from "@chakra-ui/react";
export const ChatBubble = (props)=>{
    const isMe = props.from === "me";
    const alignment = isMe ? "flex-end" : "flex-start";
    const bottomRightRadius = isMe ? 0 : 32;
    const bottomLeftRadius = isMe ? 32 : 0;
    return (
        <VStack mt={6} alignItems={alignment} alignSelf={alignment}>
            <Box
                bg={isMe ? "blue.50" : "gray.100"}
                px={6}
                py={4}
                maxW={80}
                borderTopLeftRadius={32}
                borderTopRightRadius={32}
                borderBottomLeftRadius={bottomLeftRadius}
                borderBottomRightRadius={bottomRightRadius}
            >
                {props.message}
            </Box>
            <Text fontSize="xs" color="gray">
                {props.dateSent}
            </Text>
        </VStack>
    );
}