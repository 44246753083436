import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Box,
    Icon,
    Text,
    HStack,
    Flex,
    Heading,
    VStack,
    Button,
    FormControl,
    FormLabel,
    Input,
    IconButton,
    Avatar,
    Select,
    Tag,
} from '@chakra-ui/react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import { FiArrowUpRight, FiCheck, FiMail } from 'react-icons/fi'
import { FaPlus } from 'react-icons/fa'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { Global } from '../../utils/global'
import Swal from 'sweetalert2'
import { Qualification } from './qualification'
import { useRef } from 'react'
import { useAuth } from '../../context/authContext'
import { useNavigate } from 'react-router-dom'

export const Dashboard = (props) => {
    const { authed } = useAuth();
    const inputFile = useRef(null)
    const [tagline, setTagLine] = useState("")
    const [profileState, setProfileState] = useState({})
    const [selectedDays, setSelectedDays] = useState([])
    const [days, setDays] = useState([{ value: "Monday", active: false }, { value: "Tuesday", active: false }, { value: "Wednesday", active: false }, { value: "Thursday", active: false }, { value: "Friday", active: false }, { value: "Saturday", active: false }, { value: "Sunday", active: false }])
    const [selectedConsultationTimes, setSelectedConsultationTimes] = useState([])
    const [consultationTimes, setConsultationTimes] = useState([{ value: "8:00AM", active: false }, { value: "9:00AM", active: false }, { value: "10:00AM", active: false }, { value: "11:00AM", active: false }, { value: "12:30PM", active: false }, { value: "2:00PM", active: false }, { value: "3:00PM", active: false }])
    const [qualifications, setQualifications] = useState([{ "program": "ECE", "university": "JKUAT" }])
    const [values, setValues] = useState({
        city: "",
        country: "",
        currency: "",
        fee: 0,
        program: "",
        university: "",
    })
    const [img, setImg] = useState("https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png");
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate()


    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const getProfileState = async () => {
        var userID = localStorage.getItem("userID");
        var userType = localStorage.getItem("userType");
        var token = localStorage.getItem("token");
        console.log("Getting profile state")
        axios
            .get(Global.url + "/user/" + userType + "-" + userID + "/profile", { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    setProfileState(res.data.message);
                    if ((userType == "provider") && (!profileState.qualification) && (!profileState.location) && (!profileState.photo) && (!profileState.availability) && (!profileState.fee)) {
                        setTagLine("Welcome, upcoming appointments")
                    } else if ((userType == "patient") && (!profileState.location) && (!profileState.photo)) {
                        setTagLine("Welcome, upcoming appointments")
                    } else {
                        setTagLine("Please complete the steps below to unlock and enjoy the full benefits of your account.")
                    }
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const getEvents = async () => {
        var userID = localStorage.getItem("userID");
        var userType = localStorage.getItem("userType");
        var token = localStorage.getItem("token");
        var events = []
        if (userType == "patient") {
            axios
                .get(Global.url + "/patient/" + userID + "/appointment/all", { headers: { Token: token } })
                .catch(err => {
                    // what now?
                    console.log(err);
                }).then(res => {
                    console.log(res);
                    if (res.data.success == 1) {
                        var message = res.data.message
                        for (var i = 0; i < message.length; i++) {
                            var appointment = message[i]
                            var title = "Consultation with "+appointment.provider
                            var arr = appointment.date.split("T")
                            var start = arr[0]
                            var end = arr[0]
                            var event = {"title": title,"start":start,"end":end,"time":appointment.startTime,"appointmentType":appointment.type,"appointmentID":appointment.appointmentID}
                            events.push(event)
                        }
                        setEvents(events)
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                });
        } else if (userType == "provider") {
            axios
            .get(Global.url + "/provider/" + userID + "/appointment/all", { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    for (var i = 0; i < message.length; i++) {
                        var appointment = message[i]
                        var title = "Appointment with "+appointment.patient
                        var arr = appointment.date.split("T")
                        var start = arr[0]
                        var end = arr[0]
                        var event = {"title": title,"start":start,"end":end,"time":appointment.startTime,"appointmentType":appointment.type,"appointmentID":appointment.appointmentID}
                        events.push(event)
                    }
                    setEvents(events)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
        }
        console.log("events",calendarEvents)

    };

    useEffect(() => {
        getProfileState()
        getEvents()
        console.log("auth", authed)
    }, [])

    useEffect(() => {
        console.log('Qualifications:', qualifications);
    }, [qualifications]);

    function onAddQualification(program, university) {
        var item = { "program": program, "university": university }
        qualifications.push(item);
        setQualifications(qualifications)
    }
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };
    function setLocationPhoto() {
        if (profileState.location) {
            if ((values.city == "") || (values.country == "")) {
                Swal.fire("Details Missing", "Required data has not been provided", "error")
                return
            }
            addLocation()
        } else if (profileState.photo) {
            addPhoto()
        }
    }
    function setFeeAvailability() {
        if (profileState.fee) {
            if ((values.fee == 0) || (values.currency == "")) {
                Swal.fire("Details Missing", "Required data has not been provided", "error")
                return
            }
            addFee()
        } else if (profileState.availability) {
            addAvailability()
        }
    }
    function addLocation() {
        var userType = localStorage.getItem("userType")
        var userID = localStorage.getItem("userID")

        var params = { "name": values.city, "country": values.country, "userID": userID, "type": userType }
        axios
            .post(Global.url + "/location", params)
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Location Update", res.data.message, "success").then(() => {
                        if ((profileState.photo) && (selectedFile != null)) {
                            addPhoto()
                        }
                    })
                } else {
                    Swal.fire("Location Update", res.data.message, "error");
                }
            });
    }

    function addFee() {
        var userID = localStorage.getItem("userID")

        var params = { "currency": values.currency, "fee": values.fee }
        axios
            .post(Global.url + "/provider/" + userID + "/fee", params)
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Fee Update", res.data.message, "success").then(() => {
                        if (profileState.availability) {
                            addAvailability()
                        }
                    })
                } else {
                    Swal.fire("Fee Update", res.data.message, "error");
                }
            });
    }

    function addQualification() {
        var userID = localStorage.getItem("userID")
        if ((values.program == "") || (values.university == "")) {
            Swal.fire("Details Missing", "Required data has not been provided", "error")
            return
        }
        var params = { "program": values.program, "university": values.university }
        axios
            .post(Global.url + "/provider/" + userID + "/qualification", params)
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Qualification Update", res.data.message, "success").then(() => {
                        navigate("/dashboard");
                    })
                } else {
                    Swal.fire("Qualification Update", res.data.message, "error");
                }
            });
    }
    function addAvailability() {
        var userID = localStorage.getItem("userID")
        for (var i = 0; i < days.length; i++) {
            var day = days[i];
            if (day.active) {
                selectedDays.push(day.value)
            }
        }
        setSelectedDays(selectedDays)
        for (var i = 0; i < consultationTimes.length; i++) {
            var consultationTime = consultationTimes[i];
            if (consultationTime.active) {
                selectedConsultationTimes.push(consultationTime.value)
            }
        }
        setSelectedConsultationTimes(selectedConsultationTimes)
        var params = { "days": selectedDays, "time": selectedConsultationTimes }

        axios
            .post(Global.url + "/provider/" + userID + "/availability", params)
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Availability Update", res.data.message, "success").then(() => {
                        navigate("/dashboard")
                    });
                } else {
                    Swal.fire("Availability Update", res.data.message, "error");
                }
            });
    }

    function addPhoto() {
        if (selectedFile == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var userType = localStorage.getItem("userType")
        var userID = localStorage.getItem("userID")
        var formData = new FormData();
        formData.append("image", selectedFile);
        // var params = { "name": values.city, "country": values.country, "userID": userID, "type": userType }
        axios
            .post(Global.url + "/user/" + userType + "-" + userID + "/photo", formData, { headers: { "Content-Type": "multipart/form-data" } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Photo Update", res.data.message, "success").then(() => {
                        navigate("/dashboard")
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }

    const openFile = () => {
        inputFile.current.click();
    }

    function onRemoveQualification(id) {
        console.log("Remove qualification", id)
    }
    function consultationTimeSelected(e) {
        var selectedTime = e.target.getAttribute("data-time")
        var component = document.getElementById(selectedTime)
        for (var i = 0; i < consultationTimes.length; i++) {
            if (consultationTimes[i].value == selectedTime) {
                consultationTimes[i].active = !consultationTimes[i].active
                if (consultationTimes[i].active) {
                    component.style.backgroundColor = 'teal'
                } else {
                    component.style.backgroundColor = 'gray'
                }
            }
        }
        setConsultationTimes(consultationTimes)
    }
    function consultationDaySelected(e) {
        var selectedTime = e.target.getAttribute("data-day")
        var component = document.getElementById(selectedTime)
        for (var i = 0; i < days.length; i++) {
            if (days[i].value == selectedTime) {
                days[i].active = !days[i].active
                if (days[i].active) {
                    component.style.backgroundColor = 'teal'
                } else {
                    component.style.backgroundColor = 'gray'
                }
            }
        }
        setDays(days)
        console.log(consultationTimes)
    }
    const eventClicked = (e)=>{
        var appointmentID = e.event._def.extendedProps.appointmentID;
        console.log("Clicked",)
        var swal_html = '<div><span>'
        var currentEvent = {}
        for(var i = 0;i<calendarEvents.length;i++){
            var event = calendarEvents[i]
            if(event.appointmentID == appointmentID){
                currentEvent = event
            }
        } 
        if(currentEvent != {}){
            swal_html = swal_html+currentEvent.title+'</br>Date: '+currentEvent.start+'</br>Time: '+currentEvent.time+'</br>Type: '+currentEvent.appointmentType+'</span><div>';
        }       
        Swal.fire({title:"Appointment Details", html: swal_html});
    }
    // const _events = [
    //     { title: "event 1", start: "2022-07-15", end: "2022-07-15" },
    //     { title: "event 2", start: "2022-07-20", end: "2022-07-20" }
    // ]

    const [calendarEvents, setEvents] = useState([])
    return (
        <Box
            flex={1}
            h="auto"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Dashboard</Text>
                <Text textAlign="start" fontSize={20} fontWeight="normal" color="gray.500" >{tagline}</Text>
            </Box>
            <Box>
                <Flex flexDir="row" >
                    <Accordion w="full">
                        {props.userType == "provider" && profileState.qualification ?
                            <AccordionItem>
                                <h2>
                                    <AccordionButton py={8}>
                                        <Box flex='1' textAlign='left'>
                                            Add Qualifications
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel p={8}>
                                    {/* {
                                        qualifications.map((qualification, i) =>
                                            <Qualification key={i} qualification={qualification} remove={true} onAddQualification={onAddQualification} onRemoveQualification={onRemoveQualification} />
                                        )
                                    }
                                    <Qualification key="-1" remove={false} onAddQualification={onAddQualification} onRemoveQualification={onRemoveQualification} /> */}

                                    <Flex w="full" flexDir="row" pb={8}>
                                        <Box flex={3} mr={2}>
                                            <FormControl>
                                                <FormLabel>Course</FormLabel>
                                                <Input name="program" placeholder='Course/program' onChange={onChange} />
                                            </FormControl>
                                        </Box>
                                        <Box flex={3}>
                                            <FormControl>
                                                <FormLabel>University</FormLabel>
                                                <Input name="university" placeholder='University' onChange={onChange} />
                                            </FormControl>
                                        </Box>
                                        {/* <Flex flex={1} w="full" p={8} flexDir="row">
                                            {props.remove ? <IconButton icon={<FaTimes />} rounded="full" colorScheme="blue" color={"white"} onClick={() => props.onRemoveQualification(props.key)} />
                                                : <IconButton icon={<FaPlus />} rounded="full" colorScheme="blue" color={"white"} onClick={() => props.onAddQualification(values.program, values.university)} />}
                                        </Flex> */}
                                    </Flex>
                                    <Button colorScheme="teal" variant="outline" onClick={addQualification}>Update</Button>
                                </AccordionPanel>
                            </AccordionItem> : ""
                        }
                        {(profileState.photo || profileState.location) ?
                            <AccordionItem>
                                <h2>
                                    <AccordionButton py={8}>
                                        <Box flex='1' textAlign='left'>
                                            Add location and profile photo
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Flex w="full" flexDir="row" py={4}>
                                        {profileState.location ?
                                            <Box flex={6} mr={2} pr={8}>
                                                <FormControl pb={4}>
                                                    <FormLabel>Country</FormLabel>
                                                    <Input name="country" placeholder='Country' onChange={onChange} />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>City</FormLabel>
                                                    <Input name="city" placeholder='City' onChange={onChange} />
                                                </FormControl>
                                            </Box> : ""
                                        }

                                        {profileState.photo ?

                                            <Box flex={6} w="full">
                                                <FormLabel>Click to change profile photo</FormLabel>

                                                <Avatar
                                                    size={'xl'}
                                                    src={img}
                                                    alt={'Avatar Alt'}
                                                    mb={4}
                                                    pos={'relative'}
                                                    onClick={openFile}
                                                />
                                                <Input type="file" id='file' ref={inputFile} onChange={onImageChange} style={{ display: 'none' }} />
                                            </Box> : ""
                                        }
                                    </Flex>
                                    <Button colorScheme="teal" variant="outline" onClick={setLocationPhoto}>Update</Button>
                                </AccordionPanel>
                            </AccordionItem> : ""
                        }
                        {props.userType == "provider" && (profileState.availability || profileState.fee) ?

                            <AccordionItem>
                                <h2>
                                    <AccordionButton py={8}>
                                        <Box flex='1' textAlign='left'>
                                            Set rate and availability
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Flex w="full" flexDir="row" py={4}>
                                        {profileState.fee ?
                                            <Box flex={6} mr={8}>
                                                <FormControl pb={4}>
                                                    <FormLabel>Currency</FormLabel>
                                                    <Select placeholder='Currency' name="currency" onChange={onChange}>
                                                        <option value="kes">KES</option>
                                                        <option value="usd">USD</option>
                                                        <option value="usd">USD</option>
                                                        <option value="euro">Euro</option>
                                                    </Select>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Fee per appointment</FormLabel>
                                                    <Input placeholder='Appointment fee' name="fee" onChange={onChange} />
                                                </FormControl>
                                            </Box> : ""
                                        }
                                        {profileState.availability ?
                                            <Box flex={6}>
                                                <FormControl pb={4}>
                                                    <FormLabel>Select days available</FormLabel>
                                                    <Flex flexDir="row">
                                                        {days.map((day, i) =>
                                                            <Tag size="md" key={i} id={day.value} data-day={day.value} m={2} p={4} variant='solid' onClick={consultationDaySelected} colorScheme={day.active ? "orange" : "gray"}>
                                                                {day.value}
                                                            </Tag>
                                                        )}
                                                    </Flex>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Select time available</FormLabel>
                                                    <Flex flexDir="row">
                                                        {consultationTimes.map((consultationTime, i) =>
                                                            <Tag size="md" key={i} id={consultationTime.value} data-time={consultationTime.value} onClick={consultationTimeSelected} variant='solid' m={2} p={4} colorScheme={consultationTime.active ? "orange" : "gray"}>
                                                                {consultationTime.value}
                                                            </Tag>
                                                        )}
                                                    </Flex>
                                                </FormControl>
                                            </Box> : ""
                                        }
                                    </Flex>
                                    <Button colorScheme="teal" variant="outline" onClick={setFeeAvailability}>Update</Button>
                                </AccordionPanel>
                            </AccordionItem> : ""
                        }
                    </Accordion>
                </Flex>
            </Box>

            <Box w="full" py={4}>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    events={calendarEvents}
                    initialView="dayGridMonth"
                    width="auto"
                    eventClick={eventClicked}
                    height={"580px"}
                />
            </Box>
        </Box>
    )
}