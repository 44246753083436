import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  HStack,
  Tag,
  useColorModeValue,
  Container,
} from '@chakra-ui/react';
import { Global } from '../../utils/global';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';



export const BlogTags = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};



export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src={Global.url+"/"+props.authorPhoto}
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};
const ArticleItem = (props) => {
  const [limit,setLimit] = useState(180)
  const navigate = useNavigate()
  const viewPost = (e)=>{
    navigate("/view-blog",{state:{"article":props.article}})
  }

  return (
    <Box
      marginTop={{ base: '1', sm: '5' }}
      display="flex"
      flexDirection={{ base: 'column', sm: 'row' }}
      justifyContent="space-between">
      <Box
        display="flex"
        flex="1"
        marginRight="3"
        position="relative"
        alignItems="center">
        <Box
          width={{ base: '100%', sm: '85%' }}
          zIndex="2"
          marginLeft={{ base: '0', sm: '5%' }}
          marginTop="5%">
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
            <Image
              borderRadius="lg"
              src={Global.url+"/"+props.article.photo}
              alt={props.article.author}
              objectFit="contain"
              w="380px"
              h="250px"
            />
          </Link>
        </Box>
        <Box zIndex="1" width="100%" position="absolute" height="100%">
          <Box
            bgGradient={useColorModeValue(
              'radial(orange.600 1px, transparent 1px)',
              'radial(orange.300 1px, transparent 1px)'
            )}
            backgroundSize="20px 20px"
            opacity="0.4"
            height="100%"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        marginTop={{ base: '3', sm: '0' }}
        data-id={props.article.articleID}
        onClick={viewPost}>
        <BlogTags tags={props.article.categories} />
        <Heading marginTop="1">
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
            {props.article.title}
          </Link>
        </Heading>
        <Text
          as="p"
          marginTop="2"
          color={useColorModeValue('gray.700', 'gray.200')}
          fontSize="lg">
          {props.article.content.substring(0,limit)+"..."}
        </Text>
        <BlogAuthor name={props.article.author} authorPhoto={props.article.authorPhoto} date={new Date(props.article.dateAdded)} />
      </Box>
    </Box>
  )
}

const ArticleList = (props) => {
  return (
    <Container maxW={'7xl'} p="12">
      <Heading as="h1">Latest Articles</Heading>
      {props.articles.map((article)=>
        <ArticleItem key={article.articleID} article={article} />
      )}
    </Container>
  );
};

export default ArticleList;