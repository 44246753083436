import { Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay } from "@chakra-ui/react"
import ChatHistorySidebar from "./ChatHistorySidebar"

export const ChatHistoryDrawer = (props) => {
    return (
        <Drawer isOpen={props.isOpen} placement="left" onClose={props.onClose}>
            <DrawerOverlay>
                <DrawerContent pt={8}>
                    <DrawerCloseButton />
                    <ChatHistorySidebar />
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    )
}