import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Box,
    Icon,
    Text,
    HStack,
    Flex,
    Heading,
    VStack,
    Button,
    Link,
} from '@chakra-ui/react'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'

import { FiArrowUpRight, FiCheck, FiMail } from 'react-icons/fi'
import Swal from 'sweetalert2'
import { Global } from '../../utils/global'

export const HealthPlan = () => {
    const [plans, setPlans] = useState([])
    useEffect(() => {
        const getPlans = async () => {
            var token = localStorage.getItem("token");
            console.log("Getting appointments")
            axios
                .get(Global.url + "/health/plan", { headers: { Token: token } })
                .catch(err => {
                    // what now?
                    console.log(err);
                }).then(res => {
                    console.log(res);
                    if (res.data.success == 1) {
                        setPlans(res.data.message);
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                });
        };
        getPlans()
    }, [])

    return (
        <Box
            flex={1}
            h="95vh"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}
            w="full"
            pos="sticky"
        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <HStack justifyContent="space-between">
                    <VStack align="flex-start">
                        <Text textAlign="start" fontSize={28} fontWeight="bold" >Health Plan</Text>
                        <Text textAlign="start" fontSize={20} fontWeight="normal" color="gray.500" >Manage your health plan</Text>
                    </VStack>
                    <Link href="/new-plan" _hover={{ textDecor: "none" }}><Button variant="outline" colorScheme="teal">CREATE PLAN</Button></Link>
                </HStack>

            </Box>
            <Box p={8} w="full">
                <TableContainer>
                    <Table variant='simple'>
                        <TableCaption>Health plans</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Title</Th>
                                <Th>Date</Th>
                                <Th>Time</Th>
                                <Th>Type</Th>
                                <Th>Frequency</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {plans.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No health plans</Text></Tr> : ""}
                            {plans.map((plan) =>
                                <Tr>
                                    <Td>{plan.title}</Td>
                                    <Td>{plan.date}</Td>
                                    <Td>{plan.time}</Td>
                                    <Td>{plan.type}</Td>
                                    <Td>{plan.frequency}</Td>
                                    <Td>{plan.status}</Td>
                                </Tr>
                            )}
                        </Tbody>
                        <Tfoot>
                            <Th>Title</Th>
                            <Th>Date</Th>
                            <Th>Time</Th>
                            <Th>Type</Th>
                            <Th>Frequency</Th>
                            <Th>Status</Th>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}