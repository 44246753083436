import { ReactNode } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Link,
    List,
    ListItem,
    Select,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Navbar } from '../components/navbar';
import { DoctorProfile } from '../components/doctor/doctor-profile';
import { Footer } from '../components/footer';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import { useState } from 'react';

import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Global } from '../utils/global';
import mixpanel from 'mixpanel-browser';

mixpanel.init(Global.mixpanelToken,{
    debug:true
})

export const BookDoctor = () => {
    const { state } = useLocation();
    const [patient, setPatient] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState('10:00');
    const [values, setValues] = useState({
        appointmentType: "video"
    })
    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const addAppointment = (userID) => {
        var token = localStorage.getItem("token")
        var userID = localStorage.getItem("userID")
        if(values.appointmentType == ""){
            Swal.fire("Book Doctor", "Some details are missing","error")
            return
        }
        // console.log("Get user ID: ", userID)
        var params = {"providerID":state.doctor.providerID+"", "patientID":userID, "date":startDate, "startTime":startTime, "type":values.appointmentType}
        axios
            .post(Global.url + "/appointment",params, { headers: { "token": token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    Swal.fire("Appointment", res.data.message, "success").then(()=>{
                        navigate("/appointment")
                    });
                } else {
                    Swal.fire("Appointment", res.data.message, "error");
                }
            });
    }
    const getPatient = (userID) => {
        var token = localStorage.getItem("token")
        // console.log("Get user ID: ", userID)
        axios
            .get(Global.url + "/patient/" + userID, { headers: { "token": token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");
                    // console.log(res.data.message)
                    document.getElementById("name").value = res.data.message.firstName + " " + res.data.message.lastName
                    document.getElementById("email").value = res.data.message.email
                    setPatient(res.data.message)
                } else {
                    Swal.fire("Fetch Patient", res.data.message, "error");
                }
            });
    }
    useEffect(() => {
        if (state != null) {
            if ("userID" in state) {
                //fetch patient details
                getPatient(state.userID)
            }
            if("doctor" in state){
                mixpanel.track("Doctor View",{"provider":state.doctor.providerID,"userID":state.userID})
            }
        }
    }, [state])

    return (
        <Box px={{ lg: '32', sm: "2" }}>
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box w="full">
                <HStack h="100vh" spacing={0}>
                    <Flex as="main" h="full" flex={1} borderRightColor="gray.100" borderRightWidth={1}>
                        <Box w="full" h="full" id="Doctors" px="auto" pt={12} pb={16}>
                            <HStack justifyContent="space-between" mr={4}>
                                <Heading px="4" mb={4}>Book Appointment</Heading>
                            </HStack>
                            <HStack w="full" px="4">
                                <DoctorProfile btnBookVisible="none" doctor={state.doctor} />
                                <VStack w="auto" pl={10} alignItems="start">
                                    {state.doctor.qualification.qualificationID != 0 ?
                                        <>
                                            <Text>Program</Text>
                                            <Text fontSize={24} fontWeight="bold">{state.doctor.qualification.program}</Text>
                                        </>
                                        : ""
                                    }
                                    {state.doctor.speciality != "" ?
                                        <>
                                            <Text>Speciality</Text>
                                            <Text fontSize={24} fontWeight="bold">{state.doctor.speciality}</Text>
                                        </> : ""
                                    }
                                    {state.doctor.location != "" ?
                                        <>
                                            <Text>Location</Text>
                                            <Text fontSize={24} fontWeight="bold">{state.doctor.location}</Text>
                                        </> : ""
                                    }
                                </VStack>
                            </HStack>
                            <hr w="full" />
                            <VStack w={{ lg: '50%', sm: "100%" }} py={8} px={4}>
                                <FormControl pb={4}>
                                    <FormLabel>Enter your name here</FormLabel>
                                    <Input name="name" id="name" placeholder='Name' />
                                </FormControl>
                                <FormControl pb={4}>
                                    <FormLabel>Enter your email here</FormLabel>
                                    <Input name="email" id="email" type="email" placeholder='Email' />
                                </FormControl>
                                <Flex w="full" flexDir="row" align="start">
                                    <FormControl flex={1} pb={4}>
                                        <FormLabel>Pick Date</FormLabel>
                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholder="Select date" />
                                    </FormControl>
                                    <FormControl flex={1} pb={4}>
                                        <FormLabel>Select Time</FormLabel>
                                        <TimePicker format='HH:mm a' onChange={(time) => setStartTime(time)} value={startTime} />
                                    </FormControl>
                                </Flex>
                                <FormControl flex={1} pb={4}>
                                    <FormLabel>Consultation Type</FormLabel>
                                    <Select name="appointmentType" onChange={onChange}>
                                        <option value="audio">Audio</option>
                                        <option value="video">Video</option>
                                        <option value="chat">Chat</option>
                                        <option value="in-person">In-person</option>
                                    </Select>
                                </FormControl>

                                {state.doctor.fee.price != 0 ?
                                    <FormControl>
                                        <Text>Appointment Fee</Text>
                                        <Heading>{state.doctor.fee.currency + " " + state.doctor.fee.price}</Heading>
                                    </FormControl> : ""
                                }
                                <FormControl pt={4}>
                                    <Button variant="outline" colorScheme="teal" name="name" onClick={addAppointment}>Create Appointment</Button>
                                </FormControl>
                            </VStack>
                        </Box>
                    </Flex>
                    <Flex
                        as="filter"
                        h="full"
                        maxW={{ base: "xs", xl: "sm" }}
                        display={{ base: "none", lg: "flex" }}
                        w="full"
                        p={8}>
                        <Box h="full" w="full">
                            <Text pt={4} w="full" color={"teal.400"} fontWeight="light" >Categories</Text>
                            <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                            <List w="full" >
                                {state.specialities.map((speciality) =>
                                    <ListItem key={speciality.specialityID} pt={4}>
                                        <Link>{speciality.name}</Link>
                                        <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                                    </ListItem>
                                )}

                                {/* <ListItem pt={4}>
                                    <Text>{patient.firstName + " " + patient.lastName}</Text>
                                    <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                                </ListItem> */}


                            </List>
                        </Box>


                    </Flex>
                </HStack>
            </Box>
            {/* Main Content End */}

        </Box>
    );
}