import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';

const Testimonial = (props) => {
  return <Box>{props.children}</Box>;
};

const TestimonialContent = (props) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {props.children}
    </Stack>
  );
};

const TestimonialHeading = (props) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {props.children}
    </Heading>
  );
};

const TestimonialText = (props) => {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {props.children}
    </Text>
  );
};

const TestimonialAvatar = (props) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={props.src} alt={props.name} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{props.name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {props.title}
        </Text>
      </Stack>
    </Flex>
  );
};

export const TestimonialWithBubble=()=> {
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.700')} p={12}>
      <Box w="full" py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>Reviews</Heading>
          <Text>What our clients are saying about us</Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>SMS Service</TestimonialHeading>
              <TestimonialText>
                I loved that I could talk to a doctor through SMS. They helped me take care of my younger brother who was sick.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'
              }
              name={'Hilda Karun'}
              title={'Patient'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Video appointment</TestimonialHeading>
              <TestimonialText>
                It's so cheap to talk to a doctor. I didn't know this was possible.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'
              }
              name={'Mike Juma'}
              title={'Patient'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Mindblowing Service</TestimonialHeading>
              <TestimonialText>
                I can now manage all my online appointments from one platform.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'
              }
              name={'Kevin Monaar'}
              title={'General Practitioner'}
            />
          </Testimonial>
        </Stack>
      </Box>
    </Box>
  );
}