import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Navbar } from '../components/navbar';
import { Global } from '../utils/global';

export const ResetPassword = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  })
  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  useEffect(() => {
    axios
      .get(Global.url + "/auth/reset/" + params.refID)
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        if (res.data.success == 1) {
          document.getElementById("email").value = res.data.message.email
        } else {
          Swal.fire("Error", res.data.message, "error");
        }
      });
  }, [])
  const resetPassword = () => {
    if (values.password != values.confirmPassword) {
      Swal.fire("Error", "Password are not matching", "error");
      return;
    }
    axios
      .post(Global.url + "/auth/reset", { refID:params.refID, email: values.email, password: values.password })
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        console.log(res);
        if (res.data.success == 1) {
          Swal.fire("Password Changed", res.data.message, "success").then(() => {
            navigate("/login")
          });
        } else {
          Swal.fire("Error", res.data.message, "error");
        }
      });
  }
  return (
    <Box px={{ lg: '32', sm: "2" }}>
      {/* Navbar */}
      <Navbar />
      {/* Navbar End */}

      {/* Main Content Start */}
      <Box p={4} w="full">
        <Flex
          minH={'90vh'}
          align={'center'}
          justify={'center'}
          bg={useColorModeValue('gray.50', 'gray.800')}>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
              Enter new password
            </Heading>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                placeholder="your-email@example.com"
                _placeholder={{ color: 'gray.500' }}
                type="email"
                name="email"
                id="email"
                onChange={onChange}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input type="password" name="password" onChange={onChange} />
            </FormControl>
            <FormControl id="confirmPassword" isRequired>
              <FormLabel>Confirm Password</FormLabel>
              <Input type="password" name="confirmPassword" onChange={onChange} />
            </FormControl>
            <Stack spacing={6}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={resetPassword}>
                Submit
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}