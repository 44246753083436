import { Avatar, AvatarBadge, Box, Button, Divider, Flex, Heading, HStack, IconButton, List, ListItem, Text, VStack } from "@chakra-ui/react"
import { FaBell } from "react-icons/fa"
import { RiDribbbleLine, RiInstagramLine, RiTwitterFill } from 'react-icons/ri'
import { ChatFile } from "./ChatFile"
import { ChatLink } from "./ChatLink"

export const ChatFiles = () => {
    return (
        <Flex h="full" flexDirection="column" alignItems="center" w="full" pt={8}>
            <HStack justify="space-between" w="full" px={8} mb={8}>
                <Text color="gray.500">20 March 2021</Text>
                <IconButton
                    rounded="full"
                    icon={<FaBell />}
                    color="gray.500"
                    variant="ghost"
                />
            </HStack>
            <Avatar name="Dina Harrison" size="2xl">
                <AvatarBadge bg="green.400" boxSize={8} borderWidth={4} />
            </Avatar>
            <VStack>
                <Heading size="md" mt={{ base: 0, lg: 3 }}>
                    Dina Harrison
                </Heading>
                <HStack px={8} justifyContent="center" spacing={3} mt={6}>
                    <IconButton
                        icon={<RiDribbbleLine />}
                        variant="ghost"
                        rounded="full"
                        color="gray.500"
                        h={10}
                        arial-label="Dribble Account"
                    />
                    <IconButton
                        icon={<RiInstagramLine />}
                        variant="ghost"
                        rounded="full"
                        color="gray.500"
                        h={10}
                        arial-label="Instagram Account"
                    />
                    <IconButton
                        icon={<RiTwitterFill />}
                        variant="ghost"
                        rounded="full"
                        color="gray.500"
                        h={10}
                        arial-label="Twitter Account"
                    />
                </HStack>
            </VStack>
            <Box px={8} w="full">
                <Divider mt={6} color="gray.100" />
            </Box>
            <VStack spacing={6} overflowY="auto" w="full">
                <HStack px={8} w="full" mt={6} justifyContent="space-between">
                    <Heading size="md">Shared files</Heading>
                    <Button fontWeight="normal" variant="text" size="xs" color="blue">
                        see all
                    </Button>
                </HStack>
                <List spacing={4} mt={6} w="full">
                    <ListItem>
                        <ChatFile />
                    </ListItem>
                    <ListItem>
                        <ChatFile />
                    </ListItem>
                    <ListItem>
                        <ChatFile />
                    </ListItem>
                    <ListItem>
                        <ChatFile />
                    </ListItem>
                    <ListItem>
                        <ChatFile />
                    </ListItem>
                </List>
            </VStack>
            <VStack spacing={6} overflowY="auto" w="full">
                <HStack px={8} w="full" mt={6} justifyContent="space-between">
                    <Heading size="md">Shared links</Heading>
                    <Button fontWeight="normal" variant="text" size="xs" color="blue">
                        see all
                    </Button>
                </HStack>
                <List spacing={4} mt={6} w="full">
                    <ListItem>
                        <ChatLink/>
                    </ListItem>
                    <ListItem>
                        <ChatLink/>
                    </ListItem>
                    <ListItem>
                        <ChatLink/>
                    </ListItem>
                    <ListItem>
                        <ChatLink/>
                    </ListItem>
                </List>
            </VStack>
        </Flex>
    )
}