import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react"

export const ChatFile = () => {
    return (
        <HStack w="full" px={8} spacing={3}>
            <Box w={14} h={12} rounded="lg" bg="gray.100" />
            <VStack spacing={0} alignItems="flex-start" w="full">
                <Heading fontSize={12}>
                    PhotoDenver.jpg
                </Heading>
                <HStack w="full" justifyContent="space-between">
                    <Text fontSize={12} color="gray.400">
                        10.05.2022 at 11:53
                    </Text>
                    <Text fontSize={12} color="gray.400">
                        175 Kb
                    </Text>
                </HStack>

            </VStack>
        </HStack>
    )
}