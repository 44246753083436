import { Flex } from "@chakra-ui/react"
import { AppointmentSession } from "../components/appointments/appointment-session"
import { Appointments } from "../components/appointments/appointments"
import { NewAppointment } from "../components/appointments/new-appointment"
import { Sidebar } from "../components/sidebar/sidebar"

export const AppointmentSessionPage = () => {
    return (
        <Flex>
            <Sidebar />
            <AppointmentSession/>
        </Flex>
    )
}