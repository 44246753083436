import { Box, Button, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { useState } from "react"
import { Appointments } from "../components/appointments/appointments"
import { Sidebar } from "../components/sidebar/sidebar"
import ChatSection from "../components/chat-section"



export const ChatPage = () => {
    const [activeSection, setActiveSection] = useState('upcoming')

    return (
        <Flex>
            <Sidebar />
            <ChatSection/>
        </Flex>
    )
}