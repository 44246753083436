import { ReactNode } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Link,
    List,
    ListItem,
    Select,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Navbar } from '../components/navbar';
import { DoctorProfile } from '../components/doctor/doctor-profile';
import { Footer } from '../components/footer';
import { useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { Global } from '../utils/global';

export const Doctor = (props) => {

    const [values, setValues] = useState({
        location: "",
        speciality: 0
    })
    const [doctors, setDoctors] = useState([]);

    const locations = props.locations.slice(0, 3)
    const arrSpecialities = props.specialities.slice(0, 3)

    useEffect(() => {
        setDoctors(props.doctors);
    }, [props.doctors])


    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const locationSearch = (e) => {
        var url = Global.url + "/provider/search/location/" + e.target.getAttribute("data-name")
        console.log(url);
        searchDoctors(url)
    }

    const specialitySearch = (e) => {
        var url = Global.url + "/provider/search/speciality/" + e.target.getAttribute("data-name")
        console.log(url);
        searchDoctors(url)
    }

    const searchAction = () => {
        var url = "";
        if ((values.location != "") && (values.speciality != 0)) {
            url = Global.url + "/provider/search/" + values.location + "/" + values.speciality
        } else if ((values.location == "") && (values.speciality != 0)) {
            url = Global.url + "/provider/search/speciality/" + values.speciality
        } else if ((values.location != "") && (values.speciality == 0)) {
            url = Global.url + "/provider/search/location/" + values.location
        } else {
            Swal.fire("Error", "Location and speciality missing", "error");
            return;
        }

        searchDoctors(url);
    }
    const searchDoctors = (url) => {
        axios
            .get(url)
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    setDoctors(res.data.message);
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }
    return (
        <Box px={{ lg: '32', sm: "2" }} w="full">
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box w="full" h="auto">
                <HStack h="95vh" spacing={0}>
                    <Flex as="main" h="full" flex={1} borderRightColor="gray.100" borderRightWidth={1}>
                        <Box w="full" h="full" id="Doctors" px="auto" pt={12} pb={16}>
                            <HStack justifyContent="space-between" mr={4}>
                                <Heading px="4" mb={4}>Top Doctors</Heading>
                                <Button w="auto" variant="outline" colorScheme="teal">
                                    <Link p={2} style={{ "text-decoration": "none" }} href="/register-doctor">
                                        Register as doctor
                                    </Link>
                                </Button>
                            </HStack>
                            <SimpleGrid px="4" columns={{ base: 1, md: 3 }} spacing={4}>
                                {doctors.map((doctor) =>
                                    <DoctorProfile btnBookVisible="visible" specialities={props.specialities} key={doctor.providerID} doctor={doctor}/>
                                )}
                            </SimpleGrid>
                        </Box>
                    </Flex>
                    <Flex
                        as="filter"
                        h="full"
                        maxW={{ base: "xs", xl: "sm" }}
                        display={{ base: "none", lg: "flex" }}
                        w="full"
                        p={8}>
                        <Box h="full" w="full">
                            <VStack mb={4}>
                                <FormControl mb={4}>
                                    <FormLabel>Location</FormLabel>
                                    <Input variant="filled" placeholder="Search by location" name="location" onChange={onChange} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Specialty</FormLabel>
                                    <Select placeholder="Select specialization" name="speciality" onChange={onChange}>
                                        {props.specialities.map((speciality) =>
                                            <option key={speciality.specialityID} value={speciality.specialityID}>{speciality.name}</option>
                                        )}
                                    </Select>
                                </FormControl>
                            </VStack>

                            <Button w="full" variant="outline" colorScheme="teal" onClick={searchAction}>Search</Button>

                            <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                            <Text pt={4} w="full" color={"teal.400"} fontWeight="light" >Popular locations</Text>
                            <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                            <List w="full" >
                                {
                                    locations.map((location) =>
                                        <ListItem pt={4}>
                                            <Link name="location" data-name={location} onClick={locationSearch}>{location}</Link>
                                            <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                                        </ListItem>
                                    )
                                }
                            </List>
                            <Text pt={4} w="full" color={"teal.400"} fontWeight="light" >Popular Specialties</Text>
                            <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                            <List w="full" >
                                {
                                    arrSpecialities.map((speciality) =>
                                        <ListItem pt={4}>
                                            <Link name="speciality" data-name={speciality.specialityID} onClick={specialitySearch}>{speciality.name}</Link>
                                            <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Box>


                    </Flex>
                </HStack>
            </Box>
            {/* Main Content End */}
        </Box>
    );
}