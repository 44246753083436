import { ReactNode } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    HStack,
    Input,
    Link,
    List,
    ListItem,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Navbar } from '../components/navbar';
import ArticleList from '../components/blog/article-list';
import { Footer } from '../components/footer';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Global } from '../utils/global';
import Swal from 'sweetalert2';

export const Blog = () => {
    const [articles,setArticles]=useState([])
    const getArticles = () => {
        axios
            .get(Global.url + "/article")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    setArticles(res.data.message)
                } else {
                    Swal.fire("Blog", res.data.message, "error");
                }
            });
    }
    useEffect(()=>{
        getArticles()
    },[])

    return (
        <Box px={{ lg: '32', sm: "2" }}>
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box w="full" h="auto">
                <HStack h="100vh" spacing={0}>
                    <Flex as="main" h="full" flex={1} borderRightColor="gray.100" borderRightWidth={1}>
                        <ArticleList articles={articles} />
                    </Flex>
                    <Flex
                        as="filter"
                        h="full"
                        maxW={{ base: "xs", xl: "sm" }}
                        display={{ base: "none", lg: "flex" }}
                        w="full"
                        p={8}>
                        <Box h="full" w="full">
                            <VStack>
                                <FormControl mb={4}>
                                    <Input variant="filled" placeholder="Search article" />
                                </FormControl>
                                <Button w="full" variant="outline" colorScheme="teal">Search</Button>
                                <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                                <Text pt={4} w="full" color={"teal.400"} fontWeight="light" >Categories</Text>
                                <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                                <List  w="full" >
                                    <ListItem pt={4}>
                                        <Link>Telemedicine</Link>
                                        <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                                    </ListItem>
                                    <ListItem pt={4}>
                                        <Link>Therapy</Link>
                                        <Box pt={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1} />
                                    </ListItem>
                                </List>
                            </VStack>
                        </Box>
                    </Flex>
                </HStack>
            </Box>
            {/* Main Content End */}
            <Footer/>
        </Box>
    );
}