import { Flex } from "@chakra-ui/react"
import { Appointments } from "../components/appointments/appointments"
import { Sidebar } from "../components/sidebar/sidebar"

export const AppointmentsPage = () => {
    return (
        <Flex>
            <Sidebar />
            <Appointments/>
        </Flex>
    )
}