import {
    Box,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Text,
} from '@chakra-ui/react';

import {
    FcAssistant,
    FcCustomerSupport,
    FcSearch
} from 'react-icons/fc';

const HighlightItem = (props) => {
    return (
        <Stack
            align={'center'}
            justify={'center'}
            bg="teal.50"
            p={4}
            borderRadius={8}
        >
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'gray.100'}
                mb={1}>
                {props.icon}
            </Flex>
            <Text fontWeight={600}>{props.title}</Text>
            <Text color={'gray.600'} textAlign="center">{props.text}</Text>
        </Stack>
    );
};

export const Highlight = () => {
    return (
        <Box px="auto" pt={12}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                <HighlightItem
                    icon={<Icon as={FcAssistant} w={10} h={10} />}
                    title={'Book Appointment'}
                    text={
                        'Talk to a doctor,therapist or nurse via SMS, audio/video call. 100% HIPPA compliant platform for patients to contact physicians.'
                    }
                />
                <HighlightItem
                    icon={<Icon as={FcCustomerSupport} w={10} h={10} />}
                    title={'Remote patient monitoring'}
                    text={
                        'Doctors can set follow up reminders after having an appointment. Quickmed Team available 24/7 to guide you through entire process.'
                    }
                />
                <HighlightItem
                    icon={<Icon as={FcSearch} w={10} h={10} />}
                    title={'Search doctors nearby'}
                    text={
                        'Get matched with a board certified doctor close to your area to make it easy to organize physical appointments and assessment.'
                    }
                />
            </SimpleGrid>
        </Box>
    )
}