import { Box, Button, Flex, HStack, Link, Text, VStack } from "@chakra-ui/react"

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"

export const Appointments = () => {
    const [activeSection, setActiveSection] = useState('upcoming')
    const [appointments, setAppointments] = useState([])
    const [userType, setUserType] = useState("patient")
    const navigate = useNavigate()
    useEffect(() => {
        // const getAppointments = async () => {
        //     var token = localStorage.getItem("token");
        //     console.log("Getting appointments")
        //     axios
        //         .get(Global.url + "/appointment", { headers: { Token: token } })
        //         .catch(err => {
        //             // what now?
        //             console.log(err);
        //         }).then(res => {
        //             console.log(res);
        //             if (res.data.success == 1) {
        //                 setAppointments(res.data.message);
        //             } else {
        //                 Swal.fire("Error", res.data.message, "error");
        //             }
        //         });
        // };
        setUserType(localStorage.getItem("userType"))
        getAppointments("pending")
    }, [])

    const getAppointments = async (status) => {
        setAppointments([])
        var userID = localStorage.getItem("userID");
        var userType = localStorage.getItem("userType");
        var token = localStorage.getItem("token");
        var events = []
        if (userType == "patient") {
            axios
                .get(Global.url + "/patient/" + userID + "/appointment/"+status, { headers: { Token: token } })
                .catch(err => {
                    // what now?
                    console.log(err);
                }).then(res => {
                    console.log(res);
                    if (res.data.success == 1) {
                        var message = res.data.message
                        setAppointments(message)
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                });
        } else if (userType == "provider") {
            axios
            .get(Global.url + "/provider/" + userID + "/appointment/"+status, { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setAppointments(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
        }
    };

    const startAppointment=(e)=>{
        var appointmentID = e.target.getAttribute("data-id")
        navigate("/session",{state:{"appointmentID":appointmentID}})
    }

    return (
        <Box
            flex={1}
            h="auto"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <VStack pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <HStack mb={4} w="full" justifyContent="space-between">
                    <Text fontSize={28} fontWeight="bold" >Appointments</Text>
                    <Link _hover={{ textDecor: "none" }} href="/new-appointment"><Button variant="outline" colorScheme="teal.500">Add Appointment</Button></Link>
                </HStack>
                <Flex flexDir="row" w="full">
                    <Button
                        onClick={() => {
                            setActiveSection("upcoming")
                            getAppointments("pending")
                        }}
                        backgroundColor={activeSection == "upcoming" ? "#AEC8CA" : "white"}
                        mr={4}
                        variant="outline"
                        rounded="full"
                        colorScheme="teal.500"
                    >
                        Upcoming
                    </Button>
                    <Button
                        onClick={() => {
                            setActiveSection("ongoing")
                            getAppointments("ongoing")
                        }}
                        backgroundColor={activeSection == "ongoing" ? "#AEC8CA" : "white"}
                        mr={4}
                        variant="outline"
                        rounded="full"
                        colorScheme="teal.500"
                    >
                        Ongoing
                    </Button>
                    <Button
                        onClick={() => {
                            setActiveSection("complete")
                            getAppointments("complete")
                        }}
                        backgroundColor={activeSection == "complete" ? "#AEC8CA" : "white"}
                        mr={4}
                        variant="outline"
                        rounded="full"
                        colorScheme="teal.500">
                        Complete
                    </Button>
                    <Button
                        onClick={() => {
                            setActiveSection("cancelled")
                            getAppointments("cancelled")
                        }}
                        backgroundColor={activeSection == "cancelled" ? "#AEC8CA" : "white"}
                        variant="outline"
                        rounded="full"
                        mr={4}
                        colorScheme="teal.500">
                        Cancelled
                    </Button>
                    <Button
                        onClick={() => {
                            setActiveSection("all")
                            getAppointments("all")
                        }}
                        backgroundColor={activeSection == "all" ? "#AEC8CA" : "white"}
                        variant="outline"
                        rounded="full"
                        colorScheme="teal.500">
                        All
                    </Button>
                </Flex>
            </VStack>

            <Box p={8}>
                <TableContainer>
                    <Table variant='simple'>
                        <TableCaption>{activeSection} appointments</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Date</Th>
                                <Th>Time</Th>
                                <Th>Type</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {appointments.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No appointments</Text></Tr>:""}
                            {appointments.map((appointment) =>
                                <Tr key={appointment.appointmentID}>
                                    {userType == "patient" ? <Td>{appointment.provider}</Td>:<Td>{appointment.patient}</Td>}
                                    <Td>{appointment.date.split("T")[0]}</Td>
                                    <Td>{appointment.startTime}</Td>
                                    <Td>{appointment.type}</Td>
                                    <Td>
                                        <Button
                                            variant="outline"
                                            rounded="full"
                                            colorScheme="teal.500"
                                            data-id={appointment.appointmentID}
                                            onClick={startAppointment}
                                            >
                                            View
                                        </Button>
                                    </Td>
                                </Tr>
                            )}

                        </Tbody>
                        <Tfoot>
                            <Th>Name</Th>
                            <Th>Date</Th>
                            <Th>Time</Th>
                            <Th>Type</Th>
                            <Th>Action</Th>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}