import { IconButton, Tooltip, VStack } from "@chakra-ui/react";
import * as React from "react"
import { MdDashboard, MdMail, MdSettings } from "react-icons/md"
import { HiLightningBolt, HiBell, HiTag, HiSearch } from "react-icons/hi"
import { Logo } from "../Logo";
function Navigation() {
    return (
        <VStack p={6} justifyContent="space-between" alignItems="center" w="full">
            <VStack>
                <Logo mb="6"/>
                <Tooltip label="Dashboard" placement="right">
                    <IconButton color="gray.500" icon={<MdDashboard />} arial-label="Dashboard" />
                </Tooltip>
                <Tooltip label="Actions" placement="right">
                    <IconButton color="gray.500" icon={<HiLightningBolt />} arial-label="Actions" />
                </Tooltip>
                <Tooltip label="Search" placement="right">
                    <IconButton color="gray.500" icon={<HiSearch />} arial-label="Search" />
                </Tooltip>
                <Tooltip label="Notifications" placement="right">
                    <IconButton color="gray.500" icon={<HiBell />} arial-label="Notifications" />
                </Tooltip>
                <Tooltip label="Tags" placement="right">
                    <IconButton color="gray.500" icon={<HiTag />} arial-label="Tags" />
                </Tooltip>
                <Tooltip label="Messages" placement="right">
                    <IconButton color="gray.500" icon={<MdMail />} arial-label="Messages" />
                </Tooltip>
            </VStack>
            {/* settings menu */}
            <Tooltip label="Settings" placement="right">
                <IconButton color="gray.500" icon={<MdSettings />} arial-label="Settings" />
            </Tooltip>
        </VStack>
    );
}

export default Navigation