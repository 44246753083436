import { Logo } from "./logo";
import { ReactNode } from 'react';

import {
  Box,
  Container,
  IconButton,
  Img,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { BiMailSend } from 'react-icons/bi';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { Global } from "../utils/global";
import Swal from "sweetalert2";


export const ListHeader = (props) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {props.children}
    </Text>
  );
};

export const Footer = () => {
  const [values, setValues] = useState({
    email: "",
  })

  const navigate = useNavigate()

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  const subscribe = () => {
    console.log(values)
    //authenticate here
    axios
      .post(Global.url + "/subscriber", { email: values.email })
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        console.log(res);
        if (res.data.success == 1) {
          // swal("Login", "Login success", "success");

          Swal.fire("Subscription",
            "Subscribe successful.",
            "success")
        } else {
          Swal.fire("Subscription error", res.data.message, "error");
        }
      });
  }
  return (
    <Box
      mt={8}
      w="full"
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Box as={Stack} w="full" p={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '1fr 1fr 1fr 1fr 2fr' }}
          spacing={8}>
          <Stack spacing={6}>
            <Box>
              {/* <Logo color={useColorModeValue('gray.700', 'white')} /> */}
              <Img w="128px" h="128px" src={require("../Logo.png")} />
            </Box>
            <Text fontSize={'sm'}>
              © 2022 Quickmed Technologies. All rights reserved
            </Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Product</ListHeader>
            <Link href="/">Features</Link>
            <Link href="/">Pricing</Link>
            <Link href="/doctor">Doctors</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Support</ListHeader>
            <Link href="https://www.instagram.com/quickmed_hq/" target={'_blank'}>Help Center</Link>
            <Link href="/terms">Terms of Service</Link>
            <Link href="/policy">Privacy Policy</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Follow Us</ListHeader>
            <Link href="https://www.facebook.com/QuickMed-102679535846213"  target={'_blank'}>Facebook</Link>
            <Link href="https://twitter.com/quickmed_hq"  target={'_blank'}>Twitter</Link>
            <Link href="https://www.instagram.com/quickmed_hq/" target={'_blank'}>Instagram</Link>
          </Stack>
          <Stack align={'flex-start'} pr={4}>
            <ListHeader>Stay up to date</ListHeader>
            <Stack direction={'row'}>
              <Input
                name="email"
                placeholder={'Your email address'}
                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                border={0}
                _focus={{
                  bg: 'whiteAlpha.300',
                }}
                onChange={onChange}
              />
              <IconButton
                bg={useColorModeValue('teal.400', 'teal.800')}
                color={useColorModeValue('white', 'gray.800')}
                _hover={{
                  bg: 'teal.600',
                }}
                aria-label="Subscribe"
                icon={<BiMailSend />}
                onClick={subscribe}
              />
            </Stack>
          </Stack>
        </SimpleGrid>
      </Box>
    </Box>
  );
}