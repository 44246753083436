import { Avatar, Divider, Flex, Heading, IconButton, Link, Text } from "@chakra-ui/react"
import { useEffect } from "react"
import { useState } from "react"

import { FiBook, FiCalendar, FiDollarSign, FiHome, FiInbox, FiList, FiMenu, FiPackage, FiUser, FiVoicemail } from "react-icons/fi"
import { Navigate, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { NavItem } from "./nav-item"


export const Sidebar = () => {
    const [navSize, changeNavSize] = useState("small")
    const [dashboardActive, setDashboardActive] = useState(true)
    const [userType, setUserType] = useState("")
    const [username, setUsername] = useState("")
    const [activeMenu, setActiveMenu] = useState("dashboard")

    const navigate = useNavigate()

    const isActive = () => {
        if (activeMenu == "dashboard")
            return "active"
        else
            return
    }
    useEffect(() => {
        setUserType(localStorage.getItem("userType"))
        setUsername(localStorage.getItem("username"))
    }, [])
    const logout =()=>{
        localStorage.setItem("userType","")
        localStorage.setItem("username","")
        localStorage.setItem("userID","")
        localStorage.setItem("token","")
        Swal.fire("Logout","Successfully logged out.","success").then(()=>{
            navigate("/login")
        })
    }

    return (
        <Flex
            pos="sticky"
            left={5}
            h="95vh"
            mt="2.5vh"
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderRadius={navSize == "small" ? "15px" : "30px"}
            w={navSize == "small" ? "75px" : "200px"}
            minW={navSize == "small" ? "75px" : "200px"}
            flexDirection="column"
            justifyContent="space-between">
            <Flex
                p="5%"
                flexDir="column"
                alignItems="flex-start"
                as="nav"
            >
                <IconButton
                    background="none"
                    mt={5}
                    _hover={{ background: 'none' }}
                    icon={<FiMenu />}
                    onClick={() => {
                        if (navSize == "small")
                            changeNavSize("large")
                        else
                            changeNavSize("small")
                    }}
                />
                <Link _hover={{ textDecor: "none" }} href="/dashboard" w="full">
                    <NavItem navSize={navSize} icon={FiHome} title="Dashboard" />
                </Link>
                <Link _hover={{ textDecor: "none" }} href="/appointment" w="full">
                    <NavItem navSize={navSize} icon={FiCalendar} title="Appointment" />
                </Link>
                <Link _hover={{ textDecor: "none" }} href="/chat" w="full">
                    <NavItem navSize={navSize} icon={FiInbox} title="Chat" />
                </Link>
                {userType == "patient" ? <>
                    <Link _hover={{ textDecor: "none" }} href="/plan" w="full">
                        <NavItem navSize={navSize} icon={FiPackage} title="Health Plan" />
                    </Link>
                    <Link _hover={{ textDecor: "none" }} href="/assessment" w="full">
                        <NavItem navSize={navSize} icon={FiList} title="Assessment" />
                    </Link>
                </> : ""}
                {userType == "provider" ? <>
                    <Link _hover={{ textDecor: "none" }} href="/article" w="full">
                        <NavItem navSize={navSize} icon={FiBook} title="Articles" />
                    </Link>
                </> : ""}
                <Link _hover={{ textDecor: "none" }} href="/billing" w="full">
                    <NavItem navSize={navSize} icon={FiDollarSign} title="Billing" />
                </Link>
            </Flex>
            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                mb={4}
            >

                <Divider display={navSize == "small" ? "none" : "flex"} />
                <Flex mt={4} align="center">
                    <Avatar size="sm" src="" alignContent={"flex-start"}/>
                    <Flex flexDirection="column" ml={4} display={navSize == "small" ? "none" : "flex"}>
                        <Heading size="sm">{username}</Heading>
                        <Text color="gray">{userType}</Text>
                        <Link color="gray" href="#" onClick={logout} mt={4}>Logout</Link>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}