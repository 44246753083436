import { Flex } from "@chakra-ui/react"
import { Appointments } from "../components/appointments/appointments"
import { NewAppointment } from "../components/appointments/new-appointment"
import { Sidebar } from "../components/sidebar/sidebar"

export const NewAppointmentPage = () => {
    return (
        <Flex>
            <Sidebar />
            <NewAppointment/>
        </Flex>
    )
}