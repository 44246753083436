import { Avatar, AvatarBadge, Tooltip } from "@chakra-ui/react";


const UserAvatar = (props) =>{
    return(
        <Tooltip label={props.name}>
            <Avatar name={props.name}>
                <AvatarBadge boxSize={4} bg="green.500"/>
            </Avatar>
        </Tooltip>
    )
};

export default UserAvatar;