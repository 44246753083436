import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { Navbar } from '../components/navbar';
import { Global } from '../utils/global';


export const ForgotPassword = () => {


  const [intro, setIntro] = useState("You'll get an email with a reset link")
  const [title, setTitle] = useState("Forgot your password?")
  const [done, setDone] = useState(false)
  const [values, setValues] = useState({
    email: "",
  })
  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }
  const resetPassword = () => {
    console.log(values)
    axios
      .post(Global.url + "/auth/reset/request", { email: values.email })
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        console.log(res);
        if (res.data.success == 1) {
          // swal("Login", "Login success", "success");
          setDone(true)
          setTitle("Reset link sent")
          setIntro("Kindly check your email inbox, reset link already sent. Not finding the email? Also remember to check spam or promotions folders")

        } else {
          Swal.fire("Error", res.data.message, "error");
          setTitle("An error occured, retry?")
          setIntro(res.data.message)
        }
      });
  }
  return (
    <Box px={{ lg: '32', sm: "2" }}>
      {/* Navbar */}
      <Navbar />
      {/* Navbar End */}

      {/* Main Content Start */}
      <Box p={4} w="full">
        <Flex
          minH={'90vh'}
          align={'center'}
          justify={'center'}
          bg={useColorModeValue('gray.50', 'gray.800')}>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
              {title}
            </Heading>
            <Text
              fontSize={{ base: 'sm', sm: 'md' }}
              color={useColorModeValue('gray.800', 'gray.400')}>
              {intro}
            </Text>
            <FormControl id="email" display={done ? "none" : "flex"}>
              <Input
                placeholder="your-email@example.com"
                _placeholder={{ color: 'gray.500' }}
                type="email"
                name="email"
                onChange={onChange}
              />
            </FormControl>
            <Stack spacing={6} display={done ? "none" : "flex"}>
              <Button
                variant="outline"
                colorScheme="teal"
                onClick={resetPassword}>
                Request Reset
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}