import {
  Heading,
  Avatar,
  Box,
  Text,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Global } from '../../utils/global';

export const DoctorProfile = (props) => {
  const navigate = useNavigate();
  const photoUrl = Global.url+"/"+props.doctor.photo
  const bookDoctor = () => {
    //check if the user is a patient
    var userType = localStorage.getItem("userType")
    var userID = localStorage.getItem("userID")
    if (userType == "patient"){
      navigate("/book-doctor", { state: {doctor: props.doctor, specialities:props.specialities, userID:userID} })
    }else{
      Swal.fire("Login","Login first to book appointment","info").then(()=>{
        navigate("/login",{ state: {to:"book-doctor",doctor:props.doctor, specialities:props.specialities}})
      })
    }
  }
  return (
    <Box py={6}>
      <Box
        // maxW={'320px'}
        minW={"320px"}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}>
        <Avatar
          size={'xl'}
          // src={(props.doctor.photo == "" || props.doctor.photo == null) ? "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" : props.doctor.photo}
          src={photoUrl}
          alt={'Avatar Alt'}
          mb={4}
          pos={'relative'}
          _after={{
            content: '""',
            w: 4,
            h: 4,
            bg: 'green.300',
            border: '2px solid white',
            rounded: 'full',
            pos: 'absolute',
            bottom: 0,
            right: 3,
          }}
        />
        <Heading fontSize={'2xl'} fontFamily={'body'}>
          {props.doctor.name}
        </Heading>
        { props.doctor.qualification.qualificationID != 0 ? <Text
          textAlign={'center'}
          color='gray.700'
          px={3}>
          {props.doctor.qualification.program} 
        </Text>:""
        }
        <Text fontWeight={600} color={'gray.500'} mb={4}>
          {props.doctor.location}
        </Text>
        <Text
          textAlign={'center'}
          color={useColorModeValue('gray.700', 'gray.400')}
          px={3}>
          {props.doctor.speciality}
        </Text>
        { props.doctor.fee.price != 0 ? <Text
          textAlign={'center'}
          color='gray.700'
          px={3}>
          {props.doctor.fee.currency} {props.doctor.fee.price}
        </Text>:""
        }


        <Stack mt={8} direction={'row'} spacing={4}>
          <Button
            variant="outline"
            flex={1}
            fontSize={'sm'}
            colorScheme="teal"
            boxShadow={
              '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
            }
            onClick={bookDoctor}
            visibility={props.btnBookVisible == "visible" ? "visible" : "hidden"}
          >
            Book
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}