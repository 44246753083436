import { Box, Button, Flex, HStack, Link, Text, VStack } from "@chakra-ui/react"

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"

export const Articles = () => {
    const [articles, setArticles] = useState([])
    const [limit, setLimit] = useState(60)
    // const [authorID, setAuthorID] = useState(0)
    useEffect(() => {
        const getArticles = async (authorID) => {
            var token = localStorage.getItem("token");
            console.log("Getting articles")
            axios
                .get(Global.url + "/article/author/" + authorID)
                .catch(err => {
                    // what now?
                    console.log(err);
                }).then(res => {
                    console.log(res);
                    if (res.data.success == 1) {
                        setArticles(res.data.message);
                    } else {
                        Swal.fire("Error", res.data.message, "error");
                    }
                });
        };
        // setAuthorID()
        getArticles(localStorage.getItem("userID"))
    }, [])


    return (
        <Box
            flex={1}
            h="auto"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <VStack pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <HStack mb={4} w="full" justifyContent="space-between">
                    <Text fontSize={28} fontWeight="bold" >Articles</Text>
                    <Link _hover={{ textDecor: "none" }} href="/new-article"><Button variant="outline" colorScheme="teal.500">Add Article</Button></Link>
                </HStack>
                <Flex flexDir="row" w="full">
                    <Text>My articles</Text>
                </Flex>
            </VStack>

            <Box p={8}>
                <TableContainer>
                    <Table variant='simple'>
                        <TableCaption>Articles</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Title</Th>
                                <Th>Content</Th>
                                <Th>Date Added</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {articles.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No articles</Text></Tr>:""}
                            {articles.map((article) =>
                                <Tr key={article.articleID}>
                                    <Td>{article.title}</Td>
                                    <Td>{article.content.substring(0,limit)+"..."}</Td>
                                    <Td>{article.dateAdded}</Td>
                                    <Td>
                                        <Button
                                            variant="outline"
                                            rounded="full"
                                            colorScheme="teal.500">
                                            Edit
                                        </Button>
                                    </Td>
                                </Tr>
                            )}

                        </Tbody>
                        <Tfoot>
                            <Th>Title</Th>
                            <Th>Content</Th>
                            <Th>Date Added</Th>
                            <Th>Action</Th>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}