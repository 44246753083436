
import {
    Box,
    Flex,
    useColorModeValue,
    Stack,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Image,
    StackDivider,
} from '@chakra-ui/react';

import {
    IoMdContacts,
    IoMdChatbubbles,
    IoMdListBox,
    IoIosCalendar,
    IoIosVideocam,
    IoMdSearch,
    IoMdAnalytics,
} from 'react-icons/io';

const FeatureItem = (props) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={props.iconBg}>
                {props.icon}
            </Flex>
            <Text fontWeight={600}>{props.text}</Text>
        </Stack>
    );
};

export const Feature = () => {
    return (
        <Box w="full" px="auto" py={12}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Flex>
                    <Image
                        rounded={'md'}
                        alt={'feature image'}
                        src={
                            'images/telehealth.png'
                        }
                        objectFit={'cover'}
                    />
                </Flex>
                <Stack spacing={4}>
                    <Text
                        textTransform={'uppercase'}
                        color={'blue.400'}
                        fontWeight={600}
                        fontSize={'sm'}
                        bg={useColorModeValue('blue.50', 'blue.900')}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}>
                        FEATURES
                    </Text>
                    <Heading>Quickmed platform features</Heading>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        Enjoy world class care offered by top tier clinics, shouldn't break the bank.
                    </Text>
                    <Stack
                        spacing={4}
                        divider={
                            <StackDivider
                                borderColor={useColorModeValue('gray.100', 'gray.700')}
                            />
                        }>
                        <FeatureItem
                            icon={
                                <Icon as={IoMdChatbubbles} color={'blue.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('blue.100', 'blue.900')}
                            text={'SMS Consultation'}
                        />
                        <FeatureItem
                            icon={
                                <Icon as={IoMdListBox} color={'yellow.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'Flexible health plans'}
                        />
                        <FeatureItem
                            icon={<Icon as={IoIosVideocam} color={'green.500'} w={5} h={5} />}
                            iconBg={useColorModeValue('green.100', 'green.900')}
                            text={'Audio/Video consultations'}
                        />
                        <FeatureItem
                            icon={
                                <Icon as={IoMdSearch} color={'purple.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('purple.100', 'purple.900')}
                            text={'Search doctors nearby you.'}
                        />

                        <FeatureItem
                            icon={
                                <Icon as={IoMdContacts} color={'red.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('red.100', 'red.900')}
                            text={'Provider to provider consultation'}
                        />

                        <FeatureItem
                            icon={
                                <Icon as={IoMdAnalytics} color={'teal.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('teal.100', 'teal.900')}
                            text={'Tiba -  AI powered health checkup bot'}
                        />

                    </Stack>
                </Stack>

            </SimpleGrid>
        </Box>
    )
}