import {
    Flex,
    Box,
    Stack,
    useColorModeValue,
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    Textarea,
    VStack,
    HStack,
    Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BlogTags } from '../components/blog/article-list';

import { Navbar } from '../components/navbar';
import { Global } from '../utils/global';
export const PopulateComments = (props)=>{
    return(
        props.comments.map((comment)=>(
            <VStack w = "full" spacing={4} align="start" h = "auto" dir='column'>
                <HStack mb ={2} align="flex-end" w="full" justifyContent="space-between">
                    <span>Name: {comment.name}</span>
                    <span>Date: {comment.dateAdded}</span>
                </HStack>
                <Text  mb ={2} >Message: {comment.message}</Text>
                <Flex h={.4} backgroundColor="gray.50" w="full"  mx={4}/>
            </VStack>
        ))
    )
}
export const ViewBlog = () => {
    const [article, setArticle] = useState({})
    const { state } = useLocation()
    useEffect(() => {
        if (state != null) {
            if ("article" in state) {
                setArticle(state.article)
            }
        }
    }, [state])


    const [values, setValues] = useState({
        name: "",
        email: "",
        comment: ""
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const addComment = () => {
        console.log(values)
        //authenticate here
        if ((values.comment == "") || (values.email == "") || (values.name == "")) {
            Swal.fire("Article Comment",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/article/"+article.articleID+"/comment", { "name": values.name, "email": values.email, "comment": values.comment })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Comment",
                        "Comment added successfully.",
                        "success")
                } else {
                    Swal.fire("Error", "Error occurred when adding comment", "error");
                }
            });
    }

    return (
        <Box px={{ lg: '32', sm: "2" }}>
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box p={4} w="auto" h="auto">
                <Flex
                    h="full"
                    align={'center'}
                    justify={'center'}
                    bg={useColorModeValue('white.50', 'white.800')}>
                    <HStack align="start" w="full" h="full">
                        <VStack align="start" spacing={4} mr={4} w="full" h="full" px={8} flex={5} borderRightColor={useColorModeValue('gray.100')} borderRightWidth={1}>
                            <Heading>
                                {article.title}
                            </Heading>
                            {article.categories != null ? <BlogTags tags={article.categories} mb={8} />:""}
                            <div>{article.content}</div>
                            <Flex h={.4} backgroundColor="gray.50" w="full"  mx={4}/>
                            <Flex dir='row' w = "full">
                                <Stack spacing={4} mt={8} w="50%">
                                    <FormControl id="name">
                                        <FormLabel>Name</FormLabel>
                                        <Input name="name" type="text" onChange={onChange} />
                                    </FormControl>
                                    <FormControl id="email">
                                        <FormLabel>Email address</FormLabel>
                                        <Input name="email" type="email" onChange={onChange} />
                                    </FormControl>
                                    <FormControl id="comment">
                                        <FormLabel>Comment</FormLabel>
                                        <Textarea name="comment" rows={3} type="text" onChange={onChange} />
                                    </FormControl>
                                    <Button
                                        variant={"outline"}
                                        colorScheme="teal"
                                        onClick={addComment}
                                    >
                                        Add Comment
                                    </Button>
                                </Stack>
                                <VStack ml={4} align="start" spacing={4} mt={8} w="50%">
                                    <Text fontWeight="bold">Comments</Text>
                                    {article.comments != null ? <PopulateComments comments={article.comments}/> : <Text>No comments</Text>}
                                </VStack>
                            </Flex>
                        </VStack>
                        <VStack align="start" spacing={4} w="full" px={4} mt={4} flex={1}>
                            <Text fontWeight="bold">Top Articles</Text>
                            <Text>No articles</Text>
                        </VStack>
                    </HStack>
                </Flex>
            </Box>
        </Box>
    )
}