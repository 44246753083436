import { Flex } from "@chakra-ui/react"
import { NewPlan } from "../components/plan/new-plan"
import { Sidebar } from "../components/sidebar/sidebar"

export const NewPlanPage = () => {
    return (
        <Flex>
            <Sidebar />
            <NewPlan />
        </Flex>
    )
}