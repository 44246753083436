import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Text, VStack } from "@chakra-ui/react"
import axios from "axios"


import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"

export const NewAppointment = () => {
    const [activeSection, setActiveSection] = useState('upcoming')
    const [values, setValues] = useState({
        speciality: "",
        date: "",
        time: "",
        type: "",
        doctor: "",
        patientID: "1",
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const AddAppointment = () => {
        console.log(values)
        //authenticate here
        if((values.date=="") || (values.time == "") || (values.type == "")){
            Swal.fire("Appointement",
                        "Required details missing.",
                        "error")
            return;
        }
        axios
            .post(Global.url + "/appointment", { "providerID":values.doctor, "patientID":values.patientID, "date":values.date, "startTime":values.time, "type":values.type })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Appointement",
                        "Created successfully.",
                        "success").then(() => {
                            navigate("/appointment")
                            // set token
                        })
                } else {
                    Swal.fire("Error", "Error creating appointment", "error");
                }
            });
    }

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >New Appointment</Text>

            </Box>

            <Box p={8} w="50%">
                <Flex flexDir="column" >
                    <HStack>
                        <FormControl>
                            <FormLabel>Speciality</FormLabel>
                            <Select name="speciality" onChange={onChange}>
                                <option value="therapist">Therapist</option>
                                <option value="psychologist">Psychologist</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Doctor</FormLabel>
                            <Select name="doctor" onChange={onChange}>
                                <option value="1">Dr. Jane Doe</option>
                                <option value="2">Dr. John Doe</option>
                            </Select>
                        </FormControl>
                    </HStack>
                    <HStack mt={4}>
                        <FormControl>
                            <FormLabel>Date</FormLabel>
                            <Input  name="date" onChange={onChange} type="text" placeholder="" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Time</FormLabel>
                            <Input  name="time" onChange={onChange} type="text" placeholder="" />
                        </FormControl>
                    </HStack>
                    <FormControl mt={4}>
                        <FormLabel>Type</FormLabel>
                        <Select  name="type" onChange={onChange}>
                            <option value="video">Audio</option>
                            <option value="video">Video</option>
                            <option value="video">In-person</option>
                        </Select>
                    </FormControl>
                    <Box mt={4} w="full" alignItems="center">
                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddAppointment}>
                            Add Appointment
                        </Button>
                    </Box>

                </Flex>
            </Box>
        </Box>
    )
}