import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Navbar } from '../components/navbar';
import { Global } from '../utils/global';
import { useAuth } from '../context/authContext';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
mixpanel.init(Global.mixpanelToken,{
    debug:true
})

export const Login = () => {
    const { login, authed,setAuth } = useAuth();
    const [to, setTo ] = useState("");
    const { state } = useLocation();

    const [values, setValues] = useState({
        email: "",
        password: "",
    })
    useEffect(()=>{
        if(state != null){
            if("to" in state){
                console.log(state.to)
                setTo(state.to)
            }
        }
    },[state])
    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const authorize = async () => {
        const res = await login({ email: values.email, password: values.password });
        console.log(res);
        if (res) {
            if (res.data.success == 1) {
                Swal.fire("Login",
                    "Login successful.",
                    "success").then(() => {
                        setAuth(true)
                        // set token
                        localStorage.setItem("token", res.data.message.token)
                        localStorage.setItem("userType", res.data.message.type)
                        localStorage.setItem("userID", res.data.message.userID)
                        localStorage.setItem("username", res.data.message.username)
                        mixpanel.track("login",{"userType":res.data.message.type,"userID":res.data.message.userID})
                        if(to ==""){
                            navigate("/dashboard")
                        }else{
                            state.userID = res.data.message.userID
                            navigate("/"+to,{state:state})
                        }
                    })
            } else {
                Swal.fire("Login error", res.data.message, "error");
            }
        }
    };
    // const testLogin = () => {
    //     isAuthorized()
    //     if (authed) {
    //         alert("Login success")
    //     } else {
    //         alert("Login error")
    //     }
    // }

    // const login = () => {
    //     console.log(values)
    //     //authenticate here
    //     axios
    //         .post(Global.url + "/auth/login", { email: values.email, password: values.password })
    //         .catch(err => {
    //             // what now?
    //             console.log(err);
    //         }).then(res => {
    //             console.log(res);
    //             if (res.data.success == 1) {
    //                 // swal("Login", "Login success", "success");

    //                 Swal.fire("Login",
    //                     "Login successful.",
    //                     "success").then(() => {
    //                         navigate("/dashboard")
    //                         // set token
    //                         localStorage.setItem("token",res.data.message)
    //                     })
    //             } else {
    //                 Swal.fire("Login error", res.data.message, "error");
    //             }
    //         });
    // }
    return (
        <Box px={{ lg: '32', sm: "2" }}>
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box p={4} w="full">
                <Flex
                    minH={'90vh'}
                    align={'center'}
                    justify={'center'}
                    bg={useColorModeValue('gray.50', 'gray.800')}>
                    <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                        <Stack align={'center'}>
                            <Heading fontSize={'4xl'}>Sign in to your account</Heading>
                            <Text fontSize={'lg'} color={'gray.600'}>
                                Enter your email and password to sign in
                            </Text>
                        </Stack>
                        <Box
                            rounded={'lg'}
                            bg={useColorModeValue('white', 'gray.700')}
                            boxShadow={'lg'}
                            p={8}>
                            <Stack spacing={4}>
                                <FormControl id="email">
                                    <FormLabel>Email address</FormLabel>
                                    <Input name="email" type="email" onChange={onChange} />
                                </FormControl>
                                <FormControl id="password">
                                    <FormLabel>Password</FormLabel>
                                    <Input name="password" type="password" onChange={onChange} />
                                </FormControl>
                                <Stack spacing={10}>
                                    <Stack
                                        direction={{ base: 'column', sm: 'row' }}
                                        align={'start'}
                                        justify={'space-between'}>
                                        <Checkbox>Remember me</Checkbox>
                                        <Link href="/forgot-password" color={'teal.400'}>Forgot password?</Link>
                                    </Stack>
                                    <Button
                                        variant={"outline"}
                                        colorScheme="teal"
                                        onClick={authorize}
                                    >
                                        Sign in
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Flex>
            </Box>
        </Box>
    )
}