import { Box, Flex, HStack, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import Chat from "./chat";
import { ChatFiles } from "./ChatFiles";
import { ChatFileDrawer } from "./ChatFiles/ChatHistoryDrawer";
import { ChatHistoryDrawer } from "./ChatHistory/ChatHistoryDrawer";
import ChatHistorySidebar from "./ChatHistory/ChatHistorySidebar";
import Navigation from "./navigation";

const ChatSection=() =>{
    const {
        isOpen:isChatHistoryOpen,
        onOpen:onChatHistoryOpen,
        onClose:onChatHistoryClose,
    } = useDisclosure(); 
    const {
        isOpen:isChatFilesOpen,
        onOpen:onChatFilesOpen,
        onClose:onChatFilesClose,
    } = useDisclosure();
    return (
        <HStack h="100vh" w="full" ml={8} px={8} spacing={0} borderWidth={1} borderColor="gray.100">
            {/* <Flex as="nav" h="full" maxW={16} w="full" bg="gray.100">
                <Navigation />
            </Flex> */}
            <Flex
                as="aside"
                h="full"
                maxW={{ base: "xs", xl: "sm" }}
                display={{ base: "none", lg: "flex" }}
                w="full"
                borderRightColor="gray.100"
                borderRightWidth={1} pt={8}
            >
                <ChatHistorySidebar />
            </Flex>
            <Flex as="main" h="full" flex={1} borderRightColor="gray.100" borderRightWidth={1}>
                <Chat onChatHistoryOpen={onChatHistoryOpen} onChatFilesOpen={onChatFilesOpen}/>
            </Flex>
            <Flex
                as="aside"
                h="full"
                maxW={{ base: "xs", xl: "sm" }}
                display={{ base: "none", lg: "flex" }}
                w="full"
            >
                <ChatFiles />
            </Flex>
            <ChatHistoryDrawer isOpen={isChatHistoryOpen} onClose={onChatHistoryClose}/>
            <ChatFileDrawer isOpen={isChatFilesOpen} onClose={onChatFilesClose}/>
        </HStack>
    )
}

export default ChatSection;