import { ReactNode } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Link,
    List,
    ListItem,
    Select,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Navbar } from '../components/navbar';
import { DoctorProfile } from '../components/doctor/doctor-profile';
import { Footer } from '../components/footer';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import { useState } from 'react';

import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Global } from '../utils/global';


export const Subscribe = () => {
    const { state } = useLocation();
    const [patient, setPatient] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState('10:00');
    const [amount, setAmount] = useState('kes. 1000');
    const [features, setFeatures] = useState([]);
    const [values, setValues] = useState({
        appointmentType: ""
    })
    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const addAppointment = (userID) => {
        var token = localStorage.getItem("token")
        var userID = localStorage.getItem("userID")
        // console.log("Get user ID: ", userID)
        var params = { "providerID": state.doctor.providerID + "", "patientID": userID, "date": startDate, "startTime": startTime, "type": values.appointmentType }
        axios
            .post(Global.url + "/appointment", params, { headers: { "token": token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    Swal.fire("Appointment", res.data.message, "success").then(() => {
                        navigate("/appointment")
                    });
                } else {
                    Swal.fire("Appointment", res.data.message, "error");
                }
            });
    }
    const getPatient = (userID) => {
        var token = localStorage.getItem("token")
        // console.log("Get user ID: ", userID)
        axios
            .get(Global.url + "/patient/" + userID, { headers: { "token": token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");
                    // console.log(res.data.message)
                    document.getElementById("name").value = res.data.message.firstName + " " + res.data.message.lastName
                    document.getElementById("email").value = res.data.message.email
                    setPatient(res.data.message)
                } else {
                    Swal.fire("Fetch Patient", res.data.message, "error");
                }
            });
    }
    useEffect(() => {
        // if (state != null) {
        //     if ("userID" in state) {
        //         //fetch patient details
        //         getPatient(state.userID)
        //     }
        // }
        setFeatures(_features)
        alert(features.length)
    }, [state])

    const _features = [{ "name": "test1", "description": "Test description" }, { "name": "test1", "description": "Test description" }]

    return (
        <Box px={{ lg: '32', sm: "2" }}>
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box w="full">
                <HStack h="100vh" spacing={0}>
                    <Flex as="main" h="full" flex={1}>
                        <Box w="full" h="full" id="Doctors" px="auto" pt={12} pb={16}>
                            <HStack justifyContent="space-between" mr={4}>
                                <Heading px="4" mb={4}>Subscribe</Heading>
                            </HStack>
                            <HStack w="full" px="4" mb={4}>
                                <VStack w="auto" alignItems="start">
                                    {state.subscription != "" ?
                                        <>
                                            <Text>Subscription name</Text>
                                            <Text fontSize={24} fontWeight="bold">{state.subscription}</Text>
                                        </> : ""
                                    }
                                    {amount != "" ?
                                        <>
                                            <Text>Amount</Text>
                                            <Text fontSize={24} fontWeight="bold">{amount}</Text>
                                        </> : ""
                                    }
                                </VStack>
                                <VStack w="auto" pl={10} alignItems="start">
                                    <Text>Features</Text>
                                    <HStack>
                                        {features.map((feature) => {
                                            <Flex>
                                                <Text>{feature.name}</Text>
                                                <span>{feature.description}</span>
                                            </Flex>
                                        })}
                                    </HStack>
                                </VStack>
                            </HStack>
                            <hr w="full" />
                            <VStack w={{ lg: '50%', sm: "100%" }} py={8} px={4}>
                                <FormControl pb={4}>
                                    <FormLabel>Enter your name here</FormLabel>
                                    <Input name="name" id="name" placeholder='Name' />
                                </FormControl>
                                <FormControl pb={4}>
                                    <FormLabel>Enter your email here</FormLabel>
                                    <Input name="email" id="email" type="email" placeholder='Email' />
                                </FormControl>
                                <Flex w="full" flexDir="row" align="start">
                                    <FormControl flex={1} pb={4}>
                                        <FormLabel>Pick Date</FormLabel>
                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholder="Select date" />
                                    </FormControl>
                                    <FormControl flex={1} pb={4}>
                                        <FormLabel>Select Time</FormLabel>
                                        <TimePicker onChange={(time) => setStartTime(time)} value={startTime} />
                                    </FormControl>
                                </Flex>
                                <FormControl flex={1} pb={4}>
                                    <FormLabel>Consultation Type</FormLabel>
                                    <Select name="appointmentType" onChange={onChange}>
                                        <option value="audio">Audio</option>
                                        <option value="video">Video</option>
                                        <option value="chat">Chat</option>
                                        <option value="in-person">In-person</option>
                                    </Select>
                                </FormControl>
                                <FormControl pt={4}>
                                    <Button variant="outline" colorScheme="teal" name="name" onClick={addAppointment}>Start Subscription</Button>
                                </FormControl>
                            </VStack>
                        </Box>
                    </Flex>
                </HStack>
            </Box>
            {/* Main Content End */}

        </Box>
    );
}