import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"

export const AppointmentSession = () => {
    const [token, setToken] = useState('')
    const [rating, setRating] = useState()
    const [appointment, setAppointment] = useState({})
    const [history, setHistory] = useState([])
    const [usertype, setUserType] = useState("")

    const [values, setValues] = useState({
        notes: "",
        diagnosis: "",
        medicine: "",
        comment: "",
        allergies: "",
    })

    const { state } = useLocation();


    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const onChangeRating = (value) => {
        setRating(value)
    }
    const AddCheckup = () => {
        console.log(values)
        //authenticate here
        if ((values.notes == "") || (values.diagnosis == "") || (values.allergies == "") || (values.medicine == "") || (appointment == {})) {
            Swal.fire("Check up",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/patient/" + appointment.patientID + "/checkup", { "appointmentID": appointment.appointmentID+"", "providerID": appointment.providerID+"", "notes": values.notes, "diagnosis": values.diagnosis, "allergies": values.allergies, "medicine": values.medicine }, { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Checkup",
                        "Created successfully.",
                        "success").then(()=>{
                            navigate("/session")
                        })
                } else {
                    Swal.fire("Error", "Error occurred when adding check up", "error");
                }
            });
    }
    const AddReview = () => {
        console.log(values)
        //authenticate here
        if ((rating == 0) || (values.comment == "") || (appointment == {})) {
            Swal.fire("Review",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/review", { "rating": rating + "", "review": values.comment, "providerID": appointment.providerID + "", "patientID": appointment.patientID + "" })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Review",
                        "Review added successfully.",
                        "success").then(()=>{
                            navigate("/session")
                        })
                } else {
                    Swal.fire("Error", "Error occurred when adding review", "error");
                }
            });
    }

    const getAppointment = async (appointmentID) => {
        var tokenStr = localStorage.getItem("token");
        axios
            .get(Global.url + "/appointment/" + appointmentID, { headers: { Token: tokenStr } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setAppointment(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });

    };

    const getMedicalHistory = async () => {
        if (appointment == {}) {
            Swal.fire("History",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .get(Global.url + "/patient/" + appointment.patientID + "/checkup", { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setHistory(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });

    };

    const toggleAppointmentStatus = () => {
        if (appointment.status == "pending") {
            setAppointmentStatus("ongoing")
        } else if (appointment.status == "ongoing") {
            setAppointmentStatus("completed")
        } else {
            setAppointmentStatus("pending")
        }
    }

    const cancelAppointment = () => {
        setAppointmentStatus("cancelled")
    }
    const setAppointmentStatus = (appointmentStatus) => {
        console.log(values)
        //authenticate here
        if (appointment == {}) {
            Swal.fire("Appointment",
                "Required details missing.",
                "error")
            return;
        }

        axios
            .post(Global.url + "/appointment/" + appointment.appointmentID + "/setstatus", { "status": appointmentStatus }, { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Appointment",
                        "Status updated successfully.",
                        "success")
                } else {
                    Swal.fire("Error", "Error occurred when setting status", "error");
                }
            });
    }

    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)

        if (state != null) {
            if ("appointmentID" in state) {
                console.log(state.appointmentID)
                getAppointment(state.appointmentID)
            }
        }

        if ((usertype == "provider") && (token != "")) {
            getMedicalHistory()
        }
    }, [state])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Appointment</Text>

            </Box>

            <Box p={8} w="full">
                {usertype == "provider" ?
                    <Tabs>
                        <TabList>
                            <Tab>Details</Tab>
                            <Tab>Assessment</Tab>
                            <Tab>History</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex flexDir="column" >
                                    <VStack spacing={6} align="start">
                                        {usertype == "patient" ? <p>Doctor: <span>{appointment.provider}</span></p> : <p>Patient: <span>{appointment.patient}</span></p>}
                                        <p>Date: <span>{appointment.date}</span></p>
                                        <p>Time: <span>{appointment.startTime}</span></p>
                                        <p>Status: <span>{appointment.status}</span></p>
                                    </VStack>
                                    <Box mt={4} w="full" alignItems="center">
                                        {appointment.status == "pending" && usertype == "provider" ?
                                            <Button w="200px" variant="outline" mr={4} colorScheme="teal.500" onClick={toggleAppointmentStatus}>
                                                Start
                                            </Button> : <Button w="200px" variant="outline" mr={4} colorScheme="teal.500" onClick={toggleAppointmentStatus}>
                                                End
                                            </Button>
                                        }
                                        <Button w="200px" color="gray.500" onClick={cancelAppointment}>
                                            Cancel
                                        </Button>
                                    </Box>
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <Flex flexDir="column" >
                                    <VStack spacing={6} align="start">
                                        <FormControl>
                                            <FormLabel>Notes</FormLabel>
                                            <Textarea name="notes" rows={5} onChange={onChange} type="text" placeholder="Add notes" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Diagnosis</FormLabel>
                                            <Input name="diagnosis" onChange={onChange} type="text" placeholder="Diagnosis" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Medicine</FormLabel>
                                            <Input name="medicine" onChange={onChange} type="text" placeholder="Add medication eg: paracetamol, flagyl etc" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Allergies</FormLabel>
                                            <Input name="allergies" onChange={onChange} type="text" placeholder="Add allergies" />
                                        </FormControl>

                                        <Box mt={4} w="full" alignItems="center">
                                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddCheckup}>
                                                Add Assessment
                                            </Button>
                                        </Box>
                                    </VStack>
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <TableContainer>
                                    <Table variant='simple'>
                                        <TableCaption>Medical history</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Diagnosis</Th>
                                                <Th>Notes</Th>
                                                <Th>Medicine</Th>
                                                <Th>Allergies</Th>
                                                <Th>Doctor</Th>
                                                <Th>Prescription</Th>
                                                <Th>Date</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {history.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No medical history</Text></Tr> : ""}
                                            {history.map((item) => (
                                                <Tr>
                                                    <Td>{item.diagnosis}</Td>
                                                    <Td>{item.notes}</Td>
                                                    <Td>{item.medicine}</Td>
                                                    <Td>{item.allergies}</Td>
                                                    <Td>{item.providerID}</Td>
                                                    <Td>Download</Td>
                                                    <Td>{item.dateAdded}</Td>
                                                </Tr>
                                            ))
                                            }
                                        </Tbody>
                                        <Tfoot>
                                            <Th>Diagnosis</Th>
                                            <Th>Notes</Th>
                                            <Th>Medicine</Th>
                                            <Th>Allergies</Th>
                                            <Th>Doctor</Th>
                                            <Th>Prescription</Th>
                                            <Th>Date</Th>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        </TabPanels>
                    </Tabs> :
                    <Tabs>
                        <TabList>
                            <Tab>Details</Tab>
                            <Tab>Feedback</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Flex flexDir="column" >
                                    <VStack spacing={6} align="start">
                                        {usertype == "patient" ? <p>Doctor: <span>Dr. John Doe</span></p> : <p>Patient: <span>Jane Doe</span></p>}
                                        <p>Date: <span>{appointment.date}</span></p>
                                        <p>Time: <span>{appointment.startTime}</span></p>
                                        <p>Status: <span>{appointment.status}</span></p>
                                    </VStack>
                                    <Box mt={4} w="full" alignItems="center">
                                        {appointment.status == "pending" && usertype == "provider" ?
                                            <Button w="200px" variant="outline" mr={4} colorScheme="teal.500" onClick={toggleAppointmentStatus}>
                                                Start
                                            </Button> : <Button w="200px" variant="outline" mr={4} colorScheme="teal.500" onClick={toggleAppointmentStatus}>
                                                End
                                            </Button>
                                        }
                                        <Button w="200px" color="gray.500" onClick={cancelAppointment}>
                                            Cancel
                                        </Button>
                                    </Box>
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <Flex flexDir="column" >
                                    <VStack spacing={6} align="start">
                                        <Text fontStyle="italic">How was the session?</Text>
                                        <FormControl>
                                            <FormLabel>Rating</FormLabel>
                                            <Rating id="rating" onChange={onChangeRating} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Comment</FormLabel>
                                            <Textarea name="comment" rows={3} onChange={onChange} type="text" placeholder="Add comment" />
                                        </FormControl>
                                        <Box mt={4} w="full" alignItems="center">
                                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddReview}>
                                                Add review
                                            </Button>
                                        </Box>
                                    </VStack>
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                }
            </Box>
        </Box>
    )
}