import {
    Flex,
    Button,
    Stack,
    Heading,
    Text,
    useBreakpointValue,
    Image,
  
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const Hero = () => {
    const navigate = useNavigate()
    const goToDoctorPage = ()=>{
        navigate("/doctor");
    }
    return (
        <Stack id="Howitworks" h="auto" direction={{ base: 'column', md: 'row' }}>
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Stack spacing={6} w={'full'} maxW={'lg'}>
                    <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: useBreakpointValue({ base: '20%', md: '30%' }),
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'blue.400',
                                zIndex: -1,
                            }}>
                            Medical Care
                        </Text>
                        <br />{' '}
                        <Text color={'blue.400'} as={'span'}>
                            Anytime, Anywhere.
                        </Text>{' '}
                    </Heading>
                    <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                        Quickmed is a Telehealth platform dedicated to helping patients receive alternative medical care by connecting them
                        with board certified physicians in their area.
                    </Text>
                    <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                        <Button
                            variant="outline"
                            colorScheme={'blue'}
                            onClick={goToDoctorPage}
                            >
                            Book Appointment
                        </Button>
                        {/* <Button rounded={'full'}>Subscribe</Button> */}
                    </Stack>
                </Stack>
            </Flex>
            <Flex flex={1}>
                <Image
                    alt={'Login Image'}
                    objectFit={'cover'}
                    src={
                        'images/telehealth_session.jpg'
                    }
                />
            </Flex>
        </Stack>
    )
}